import {
	Box,
	Flex,
	Grid,
	SimpleGrid,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

// Custom components
import Brand from 'components/card/Brand';
import Banner from 'views/client/brands/components/Banner';
import Description from 'views/client/brands/components/Description';

// Assets
import AvatarSimmmple from 'assets/img/avatars/avatarSimmmple.png';
import LinkBuilder from 'components/modal/linkBuilder/LinkBuilder';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Summary from 'views/client/brands/components/Summary';

export default function BrandDetail() {
	const textColor = useColorModeValue('gray.900', 'gray.100');

	const location = useLocation();
	const navigate = useNavigate();

	const brandData = location.state?.brandData;
	const brand = location.state?.brand;
	const brandImg = location.state?.brandImg;

	const otherBrands = brandData?.filter((b) => b.id !== brand.id);

	const shuffledOtherBrands = otherBrands
		?.sort(() => 0.5 - Math.random())
		.slice(0, 4);

	const [linkBuilder, setLinkBuilder] = useState({
		state: false,
		title: 'Crea tu link personalizado',
		subtitle: '',
		brand: '',
	});

	const handleCloseModal = () => {
		setLinkBuilder({
			state: false,
			title: '',
			subtitle: '',
			brand: '',
		});
	};

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, [brand]);

	return (
		<Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
			<Grid
				mb='20px'
				maxW='100%'
				gridTemplateColumns={{
					base: '1fr',
					lg: '1fr 1fr',
					'2xl': '1fr 0.95fr',
				}}
				gap={{ base: '20px', xl: '20px' }}
				display={{ base: 'block', lg: 'grid' }}
			>
				<Flex flexDirection='column' gridArea='1 / 1 / 2 / 2'>
					<Banner image={brandImg} />
					<Description brandName={brand?.brandName} desc={brand?.description} />
				</Flex>

				<Flex flexDirection='column' gridArea='1 / 2 / 2 / 3' pt='60px'>
					<Summary
						brand={brand}
						categoryAvatar={AvatarSimmmple}
						setLinkBuilder={setLinkBuilder}
					/>
				</Flex>
			</Grid>
			<Text
				mt='25px'
				mb='36px'
				color={textColor}
				fontSize='2xl'
				ms='24px'
				fontWeight='700'
			>
				Explora otras marcas
			</Text>
			<SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px'>
				{shuffledOtherBrands?.map((brand) => {
					let image;
					try {
						image = require(`../../../assets/img/brands/${brand.image.replace(
							'.png',
							'.jpg'
						)}`);
					} catch (error) {
						image = require('../../../assets/img/nfts/Nft5.png');
					}

					return (
						<Brand
							key={brand.id}
							brand={brand}
							image={image}
							navigate={() =>
								navigate('/client/dashboard/detail', {
									state: {
										brand: brand,
										brandImg: image,
										brandData: brandData,
									},
								})
							}
						/>
					);
				})}
			</SimpleGrid>
			<LinkBuilder
				isOpen={linkBuilder.state}
				onClose={handleCloseModal}
				title={linkBuilder.title}
				subtitle={linkBuilder.subtitle}
				brand={linkBuilder.brand}
			/>
		</Box>
	);
}
