// firebase.js
import { updatePushNotificationToken } from 'api/user';
import { initializeApp } from 'firebase/app';
import {
	getMessaging,
	getToken,
	isSupported,
	onMessage,
} from 'firebase/messaging';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let messaging;
const initMessaging = async () => {
	const supported = await isSupported();
	if (supported) {
		messaging = getMessaging(app);
		return messaging;
	} else {
		console.log('This browser does not support Firebase Messaging.');
		return null;
	}
};

export const requestForToken = async (TOKEN, USERID) => {
	const messagingInstance = await initMessaging();
	if (!messagingInstance) return;

	try {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			console.log('Notification permission granted.');

			const currentToken = await getToken(messagingInstance, {
				vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID,
			});
			if (currentToken) {
				if (TOKEN && USERID) {
					await updatePushNotificationToken(TOKEN, currentToken);
				} else {
					console.log('No user identified.');
				}
			} else {
				console.log(
					'No registration token available. Request permission to generate one.'
				);
			}
		} else {
			console.log('Unable to get permission to notify.');
		}
	} catch (err) {
		console.error('An error occurred while retrieving token: ', err);
	}
};

export const onMessageListener = async () => {
	const messagingInstance = await initMessaging();
	if (!messagingInstance) return;

	return new Promise((resolve) => {
		onMessage(messagingInstance, (payload) => {
			resolve(payload);
		});
	});
};
