import {
	Alert,
	AlertIcon,
	Button,
	Card,
	Flex,
	Icon,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react';
import { submitAutomatic, submitInvoice } from 'api/transaction';
import logoLightMode from 'assets/icon/bumbei_logo_RGB-02.png';
import logoDarkMode from 'assets/icon/bumbei_logo_RGB-04.png';
import AutomaticOption from 'components/modal/withdrawal/components/AutomaticOption';
import InvoiceOption from 'components/modal/withdrawal/components/InvoiceOption';
import { ERROR, SUCCESS } from 'constant/messages';
import { useState } from 'react';
import { GrTransaction } from 'react-icons/gr';
import { TbFileInvoice } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'store/user';

export default function WithdrawalModal({
	isOpen,
	onClose,
	userBalanceAvailable,
	userLightningAddress,
}) {
	const colorPrimary = useColorModeValue('gray.900', 'gray.100');
	const colorSecondary = useColorModeValue('gray.100', 'gray.900');
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const [modalOption, setModalOption] = useState('');
	const [amount, setAmount] = useState('');
	const [invoice, setInvoice] = useState('');
	const [message, setMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { colorMode } = useColorMode();

	const handleCloseModal = () => {
		onClose();
		setMessage({
			state: false,
			status: '',
			description: '',
		});
		setModalOption('');
		setAmount('');
		setInvoice('');
	};

	const handleMessage = (status, description) => {
		setMessage({ state: 'true', status: status, description: description });
	};

	const handleInvoiceSubmit = async () => {
		try {
			setLoading(true);
			const response = await submitInvoice(TOKEN, invoice);
			const result = await response.json();

			if (response.status === 200 && result.status === 'SUCCESS') {
				const amountTransferred = result.data.amount;
				dispatch(userActions.subtractAndAddBalance({ amountTransferred }));
				handleMessage('success', SUCCESS.TRANSACTION_SUCCESS);
			} else {
				let errorMessage;

				switch (result?.error) {
					case 'Invalid amount.':
						errorMessage = ERROR.AMOUNT_TRANSACTION;
						break;
					case 'Lightning invoice has expired.':
						errorMessage = ERROR.ALREADY_TRANSACTION;
						break;
					case 'Unable to find a lightning payment route.':
						errorMessage = ERROR.NOT_FOUND_TRANSACTION;
						break;
					case 'Invalid lightning invoice.':
						errorMessage = ERROR.INVALID_INVOICE_TRANSACTION;
						break;
					case 'Exchange rate is unavailable for the given currency pair.':
						errorMessage = ERROR.EXCHANGE_TRANSACTION;
						break;
					default:
						errorMessage = ERROR.FAILED_TRANSACTION;
						break;
				}

				handleMessage('error', errorMessage);
			}
		} catch (error) {
			handleMessage('error', ERROR.FAILED_TRANSACTION);
			console.error(error);
		} finally {
			setModalOption('message');
			setInvoice('');
			setLoading(false);
		}
	};

	const handleAutomaticSubmit = async () => {
		try {
			setLoading(true);
			const response = await submitAutomatic(
				TOKEN,
				userLightningAddress,
				amount
			);
			const result = await response.json();

			if (response.status === 200 && result.status === 'SUCCESS') {
				const amountTransferred = result.data.amount;
				dispatch(userActions.subtractAndAddBalance({ amountTransferred }));
				setModalOption('message');
				setAmount('');
				handleMessage('success', SUCCESS.TRANSACTION_SUCCESS);
			} else {
				let errorMessage;

				switch (result?.error) {
					case 'Unable to find a lightning payment route.':
						errorMessage = ERROR.NOT_FOUND_TRANSACTION;
						break;

					case 'Exchange rate is unavailable for the given currency pair.':
						errorMessage = ERROR.EXCHANGE_TRANSACTION;
						break;
					default:
						errorMessage = ERROR.FAILED_TRANSACTION;
						break;
				}

				handleMessage('error', errorMessage);
			}
		} catch (error) {
			setModalOption('message');
			setAmount('');
			handleMessage('error', ERROR.FAILED_TRANSACTION);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<ModalOverlay />
			{
				<ModalContent
					bg={colorSecondary}
					maxWidth={'700px'}
					minHeight={'450px'}
				>
					{!modalOption && (
						<>
							<ModalHeader
								display='flex'
								alignItems='center'
								gap='10px'
								pt='30px'
								textAlign='left'
							>
								Opciones de pago
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody pb={6}>
								<Text pb={10}>
									Elige una de las siguientes opciones para realizar tu retiro
								</Text>
								<Card
									bg={colorSecondary}
									p={'15px'}
									mb={'15px'}
									cursor={'pointer'}
									transition='all 0.3s'
									_hover={{ transform: 'scale(1.05)' }}
									onClick={() => setModalOption('automatic')}
								>
									<Flex gap={'10px'} align={'center'}>
										<Icon fontSize='23px' color={colorPrimary}>
											<GrTransaction />
										</Icon>
										<Text fontSize={'lg'} fontWeight={'700'}>
											Retiro automático
										</Text>
									</Flex>
									<Text ml={'15px'} my={'10px'}>
										Si cuentas con una dirección Lightning, ingresa la cantidad
										y recibe al instante Bumbei cashback.{' '}
									</Text>
								</Card>
								<Card
									bg={colorSecondary}
									p={'15px'}
									mb={'15px'}
									cursor={'pointer'}
									transition='all 0.3s'
									_hover={{ transform: 'scale(1.05)' }}
									onClick={() => setModalOption('invoice')}
								>
									<Flex gap={'10px'} align={'center'}>
										<Icon fontSize='23px' color={colorPrimary}>
											<TbFileInvoice />
										</Icon>
										<Text fontSize={'lg'} fontWeight={'700'}>
											Retiro con factura
										</Text>
									</Flex>
									<Text ml={'15px'} my={'10px'}>
										Copia y pega tu factura para recibir Bumbei cashback.{' '}
									</Text>
								</Card>
							</ModalBody>
						</>
					)}
					{modalOption === 'automatic' && (
						<AutomaticOption
							userBalanceAvailable={userBalanceAvailable}
							userLightningAddress={userLightningAddress}
							amount={amount}
							setAmount={setAmount}
							handleSubmit={handleAutomaticSubmit}
							loading={loading}
						/>
					)}
					{modalOption === 'invoice' && (
						<InvoiceOption
							userBalanceAvailable={userBalanceAvailable}
							userLightningAddress={userLightningAddress}
							invoice={invoice}
							setInvoice={setInvoice}
							handleSubmit={handleInvoiceSubmit}
							loading={loading}
						/>
					)}
					{!loading && message && modalOption === 'message' && (
						<>
							<ModalHeader
								display='flex'
								alignItems='center'
								gap='10px'
								pt='30px'
								textAlign='left'
							>
								Resultado de la transacción
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody pb={6}>
								<Text>
									{message.status === 'error'
										? 'Error en la operación'
										: 'Operación exitosa'}
								</Text>
								<Flex
									direction='column'
									justifyContent='center'
									alignItems='center'
									height='100%'
									px='35px'
								>
									<Image
										w='50%'
										src={colorMode === 'light' ? logoLightMode : logoDarkMode}
									/>
									<Alert
										status={message.status}
										bg={message.status === 'error' ? 'brand.500' : colorPrimary}
										borderRadius={'20px'}
										justifyContent='center'
										w='fit-content'
									>
										<AlertIcon color={colorSecondary} />
										<Text color={colorSecondary}>{message.description}</Text>
									</Alert>
								</Flex>
							</ModalBody>
						</>
					)}

					<ModalFooter gap='20px'>
						{modalOption && (
							<Button
								onClick={() => {
									setModalOption('');
									setAmount('');
									setInvoice('');
								}}
							>
								Volver
							</Button>
						)}
						<Button onClick={handleCloseModal}>Cerrar</Button>
					</ModalFooter>
				</ModalContent>
			}
		</Modal>
	);
}
