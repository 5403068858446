// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';

export default function Banner(props) {
	const { desc, brandName } = props;
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('background.100', 'gray.500');

	return (
		<Card bg={cardBg} p='30px' mb={{ base: '20px', '2xl': '20px' }}>
			<Text
				color={textColor}
				fontSize={{ base: '36px', '2xl': '54px' }}
				fontWeight='700'
				mb='20px'
			>
				{brandName || 'Acerca de la marca'}
			</Text>
			<Text color={textColor} mb='20px' fontSize='md' fontWeight='500'>
				{desc} Genera tu enlace bumbei y compártelo con tu comunidad. Recibirás
				a cambio un porcentaje en Bitcoin.
			</Text>
		</Card>
	);
}
