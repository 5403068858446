import { ChakraProvider } from '@chakra-ui/react';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SessionTimeout from 'utils/SessionTimeout';
import './assets/css/App.css';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import ClientLayout from './layouts/client';
import initialTheme from './theme/theme';

export default function Main() {
	const [currentTheme, setCurrentTheme] = useState(initialTheme);
	const location = useLocation();

	// Only show the SessionTimeout modal if the current path includes "client"
	const showSessionTimeout = location.pathname.includes('client');

	return (
		<ChakraProvider theme={currentTheme}>
			{showSessionTimeout && (
				<SessionTimeout
					timeoutDuration={process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS}
				/>
			)}

			<Routes>
				<Route path='auth/*' element={<AuthLayout />} />
				<Route
					path='admin/*'
					element={
						<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
					}
				/>
				<Route
					path='client/*'
					element={
						<ClientLayout theme={currentTheme} setTheme={setCurrentTheme} />
					}
				/>
				<Route path='/' element={<Navigate to='/auth' replace />} />
			</Routes>
		</ChakraProvider>
	);
}
