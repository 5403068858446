// Chakra imports
import { Flex, Image, useColorMode } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

// Assets
import logoLightMode from 'assets/icon/bumbei_logo_RGB-02.png';
import logoDarkMode from 'assets/icon/bumbei_logo_RGB-04.png';

import miniLogoLightMode from 'assets/icon/bumbei_icon_RGB-15.png';
import miniLogoDarkMode from 'assets/icon/bumbei_icon_RGB-16.png';

export function SidebarBrand(props) {
	const { mini, hovered } = props;
	const { colorMode } = useColorMode();

	return (
		<Flex alignItems='center' flexDirection='column'>
			{mini && !hovered === true ? (
				<Image
					w={'50px'}
					mb={5}
					src={colorMode === 'light' ? miniLogoLightMode : miniLogoDarkMode}
				/>
			) : (
				<Image
					ml={'-20px'}
					src={colorMode === 'light' ? logoLightMode : logoDarkMode}
				/>
			)}
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
