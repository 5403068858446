// Chakra Imports
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Icon,
	Image,
	SimpleGrid,
	Text,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import Dark from 'assets/img/layout/Dark.png';
import DefaultSidebar from 'assets/img/layout/DefaultSidebar.png';
import DefaultSidebarDark from 'assets/img/layout/DefaultSidebarDark.png';
import Light from 'assets/img/layout/Light.png';
import MiniSidebar from 'assets/img/layout/MiniSidebar.png';
import MiniSidebarDark from 'assets/img/layout/MiniSidebarDark.png';
// Assets
import { HSeparator } from 'components/separator/Separator';
import { MdSettings } from 'react-icons/md';
import ConfiguratorRadio from './ConfiguratorRadio';
export default function HeaderLinks(props) {
	//eslint-disable-next-line
	const { colorMode, toggleColorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const MiniSidebarImage = useColorModeValue(MiniSidebar, MiniSidebarDark);
	const DefaultSidebarImage = useColorModeValue(
		DefaultSidebar,
		DefaultSidebarDark
	);
	const navbarIcon = useColorModeValue('gray.900', 'gray.100');
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const bgContrast = useColorModeValue('secondaryGray.300', 'navy.900');
	const borderButton = useColorModeValue('secondaryGray.100', '#323B5D');
	const bgSeparator = useColorModeValue('secondaryGray.400', '#323B5D');
	const drawerBg = useColorModeValue('gray.100', 'gray.900');

	const shadowBlock = useColorModeValue(
		'0px 6px 14px rgba(200, 207, 215, 0.6)',
		'none'
	);
	const configuratorShadow = useColorModeValue(
		'-20px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'-22px 32px 51px 4px #0B1437'
	);

	return (
		<>
			<Button
				variant='no-hover'
				bg='transparent'
				p='0px'
				minW='unset'
				minH='unset'
				h='18px'
				w='max-content'
				onClick={onOpen}
			>
				<Icon me='10px' h='18px' w='18px' color={navbarIcon} as={MdSettings} />
			</Button>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={'right'}
				blockScrollOnMount={false}
			>
				<DrawerOverlay />
				<DrawerContent
					boxShadow={configuratorShadow}
					w={{ base: 'calc(100vw - 32px)', md: '400px' }}
					maxW={{ base: 'calc(100vw - 32px)', md: '400px' }}
					ms={{
						base: '0px',
						sm: '16px',
					}}
					me={{
						base: '16px',
					}}
					my={{
						sm: '16px',
					}}
					borderRadius='16px'
					bg={drawerBg}
				>
					<DrawerHeader
						w={{ base: '100%', md: '400px' }}
						pt='24px'
						pb='0px'
						px='24px'
					>
						<DrawerCloseButton color={textColor} />
						<Flex alignItems='center'>
							<Box>
								<Text color={textColor} fontSize='xl' fontWeight='700'>
									Configuración
								</Text>
							</Box>
						</Flex>
						<HSeparator my='30px' bg={bgSeparator} />
					</DrawerHeader>
					<DrawerBody
						pt='0px'
						pb='24px'
						w={{ base: '100%', md: '400px' }}
						maxW='unset'
					>
						<Flex flexDirection='column'>
							<Text color={textColor} mb='12px' fontWeight={'700'}>
								Modo de color
							</Text>
							<SimpleGrid columns={2} gap='20px' mb='30px'>
								<ConfiguratorRadio
									onClick={colorMode === 'dark' ? toggleColorMode : null}
									active={colorMode === 'dark' ? false : true}
									label={<Text>Claro</Text>}
								>
									<Image
										src={Light}
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='8px'
									/>
								</ConfiguratorRadio>
								<ConfiguratorRadio
									onClick={colorMode === 'light' ? toggleColorMode : null}
									active={colorMode === 'light' ? false : true}
									label={<Text>Oscuro</Text>}
								>
									<Image
										src={Dark}
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='8px'
									/>
								</ConfiguratorRadio>
							</SimpleGrid>
							<Text color={textColor} mb='12px' fontWeight={'700'}>
								Barra lateral
							</Text>
							<SimpleGrid columns={2} gap='20px' mb='30px'>
								<ConfiguratorRadio
									onClick={() => props.setMini(false)}
									active={props.mini === true ? false : true}
									label={<Text>Por defecto</Text>}
								>
									<Flex
										py='25px'
										px='18px'
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bg={bgContrast}
										overflow='hidden'
										maxW={{ base: '100%', md: '130px' }}
									>
										<Image
											src={DefaultSidebarImage}
											maxW={{ base: '100%', md: '96px' }}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
								<ConfiguratorRadio
									onClick={() => props.setMini(true)}
									active={props.mini === false ? false : true}
									label={<Text>Minimizado</Text>}
								>
									<Flex
										py='27.5px'
										px='28px'
										maxW={{ base: '100%', md: '130px' }}
										borderRadius='10px'
										border='1px solid'
										borderColor={borderButton}
										bg={bgContrast}
										overflow='hidden'
									>
										<Image
											src={MiniSidebarImage}
											maxW={{ base: '100%', md: '75px' }}
											boxShadow={shadowBlock}
										/>
									</Flex>
								</ConfiguratorRadio>
							</SimpleGrid>
						</Flex>
						<HSeparator my='30px' bg={bgSeparator} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
