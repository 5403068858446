import {
	Avatar,
	Button,
	Flex,
	Heading,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import Card from 'components/card/Card.js';
import { VSeparator } from 'components/separator/Separator';
import CopyableChip from 'components/table/copyableChip';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	getSatoshis,
	satoshiToBtc,
	satoshiToMxn,
	satoshiToUsd,
} from 'utils/format';

export default function Banner(props) {
	const { profile } = props;

	const navigate = useNavigate();
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const headerColor = useColorModeValue('gray.100', 'gray.900');
	const textColorLink = useColorModeValue('blue.500', 'gray.100');
	const balanceBg = useColorModeValue('brand.900', 'gray.100');
	const cardBalanceBg = useColorModeValue('background.100', 'gray.500');
	const {
		userFirstName,
		userLastName,
		userBalanceAvailable,
		userBalancePending,
		userBalanceTotal,
		userReferralCode,
		userReferrals,
	} = useSelector((state) => state.user);

	const [mxnValue, setMxnValue] = useState(0);
	const [usdValue, setUsdValue] = useState(0);
	const [currentCurrency, setCurrentCurrency] = useState('SATS');

	useEffect(() => {
		const fetchConversionRates = async () => {
			try {
				const response = await fetch(
					'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=mxn,usd'
				);
				const data = await response.json();
				const conversionRateMxn = data.bitcoin.mxn;
				const conversionRateUsd = data.bitcoin.usd;
				setMxnValue(conversionRateMxn);
				setUsdValue(conversionRateUsd);
			} catch (error) {
				console.error('Error fetching conversion rates:', error);
			}
		};

		fetchConversionRates();
	}, []);

	const handleCurrencyChange = () => {
		if (currentCurrency === 'SATS') {
			setCurrentCurrency('USD');
		} else if (currentCurrency === 'USD') {
			setCurrentCurrency('BTC');
		} else if (currentCurrency === 'MXN') {
			setCurrentCurrency('SATS');
		} else {
			setCurrentCurrency('MXN');
		}
	};

	return (
		<Flex
			mb={{ base: '20px', '2xl': '20px' }}
			m={{ base: '0' }}
			justifyContent='center'
			align='center'
			direction='column'
			w={{ base: 'auto', md: '100%' }}
			mx='1rem'
		>
			<Avatar
				src={profile}
				h={{ base: '70px', md: '170px' }}
				w={{ base: '70px', md: '170px' }}
				mb='20px'
			/>
			<Heading
				color={textColor}
				fontSize={{ base: '32px', md: '54px' }}
				fontWeight='700'
				lineHeight='100%'
				textAlign='center'
			>
				Hola, {userFirstName ? `${userFirstName} ${userLastName}` : 'friend'}
			</Heading>
			<Flex order={{ base: 4, md: 1 }}>
				<NavLink to={'/client/dashboard'}>
					<Button
						px='1rem'
						textDecoration='underline'
						bg={balanceBg}
						color={headerColor}
						cursor='pointer'
						my={{ base: '0.5rem', md: '2rem' }}
					>
						Explorar marcas
					</Button>
				</NavLink>
			</Flex>
			<Card
				order={{ base: 1, md: 2 }}
				bgColor={cardBalanceBg}
				maxW='100%'
				w='900px'
				py='40px'
				mb='40px'
				mt={{ base: '40px', md: '0' }}
			>
				<Flex
					justify='space-between'
					p='20px'
					mb='20px'
					borderRadius='16px'
					bgColor={balanceBg}
					bgImage={balanceImg}
					bgPosition='right'
					bgSize='cover'
					transition='all 0.3s'
					_hover={{ transform: 'scale(1.01)' }}
					cursor='pointer'
					onClick={handleCurrencyChange}
				>
					<Flex
						color={headerColor}
						align='center'
						justify='space-between'
						w='100%'
					>
						<Flex flexDirection='column'>
							<Text fontSize='sm' fontWeight='500' mb={2}>
								Balance total
							</Text>
							<Text
								fontSize={{ base: '24px', md: '34px' }}
								fontWeight='700'
								lineHeight='100%'
							>
								{currentCurrency === 'SATS' &&
									`${getSatoshis(userBalanceTotal)} sats`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalanceTotal, usdValue)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalanceTotal)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalanceTotal, mxnValue)} MXN`}
							</Text>
							<Text mt={1} fontSize='14px' fontWeight='300' lineHeight='100%'>
								{currentCurrency === 'MXN'
									? `${getSatoshis(userBalanceTotal)} sats`
									: `$${satoshiToMxn(userBalanceTotal, mxnValue)} MXN`}
							</Text>
						</Flex>
					</Flex>
				</Flex>
				<Flex
					w='100%'
					justify={{ base: 'center', md: 'space-around' }}
					direction={{ base: 'column', md: 'row' }}
				>
					<Flex
						direction='column'
						align='center'
						mb={{ base: '20px', md: '0px' }}
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						cursor='pointer'
						onClick={() => navigate('/client/wallet')}
					>
						<Flex align='center'>
							<Text
								color={textColor}
								fontSize={{ base: '24px', lg: '32px' }}
								fontWeight='700'
							>
								{currentCurrency === 'SATS' &&
									`${getSatoshis(userBalanceAvailable)} sats`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalanceAvailable, usdValue)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalanceAvailable)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalanceAvailable, mxnValue)} MXN`}
							</Text>
						</Flex>
						<Text color={textColor} fontSize='sm' fontWeight='500'>
							Balance disponible
						</Text>
					</Flex>
					<VSeparator />
					<Flex
						direction='column'
						align='center'
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						cursor='pointer'
						onClick={() => navigate('/client/wallet')}
					>
						<Flex align='center'>
							<Text
								color={textColor}
								fontSize={{ base: '24px', lg: '32px' }}
								fontWeight='700'
							>
								{currentCurrency === 'SATS' &&
									`${getSatoshis(userBalancePending)} sats`}
								{currentCurrency === 'USD' &&
									`$${satoshiToUsd(userBalancePending, usdValue)} USD`}
								{currentCurrency === 'BTC' &&
									`${satoshiToBtc(userBalancePending)} BTC`}
								{currentCurrency === 'MXN' &&
									`$${satoshiToMxn(userBalancePending, mxnValue)} MXN`}
							</Text>
						</Flex>
						<Text color={textColor} fontSize='sm' fontWeight='500'>
							Balance pendiente
						</Text>
					</Flex>
				</Flex>
			</Card>
			<Flex
				order={{ base: 3, md: 3 }}
				flexDirection='column'
				gap={'30px'}
				mb={'40px'}
				textAlign={'center'}
			>
				<Text color={textColor} fontSize='lg' fontWeight='500'>
					Refiere a tus amigos 🎉
				</Text>

				<CopyableChip
					value={`go.bumbei.com/inicio?r=${userReferralCode}`}
					copyable={true}
				/>
			</Flex>
			<Card
				order={{ base: 2, md: 4 }}
				bgColor={cardBalanceBg}
				maxW='100%'
				w='900px'
				py='40px'
				mb='40px'
			>
				<Flex
					direction='column'
					align='center'
					mb={{ base: '20px', md: '0px' }}
				>
					<Flex align='center'>
						<Text
							color={textColor}
							fontSize={{ base: '24px', lg: '32px' }}
							fontWeight='700'
						>
							{userReferrals}
						</Text>
					</Flex>
					<Text color={textColor} fontSize='sm' fontWeight='500'>
						Total referidos
					</Text>
				</Flex>
			</Card>
		</Flex>
	);
}
