import API_URL from '../config';

export const submitAutomatic = async (TOKEN, lnAddress, amount) => {
	try {
		const response = await fetch(
			API_URL + 'client/api/v1/transaction/strikeCreateQuote',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					lnAddress: lnAddress,
					amount: amount,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const submitInvoice = async (TOKEN, lnInvoice) => {
	try {
		const response = await fetch(
			API_URL + 'client/api/v1/transaction/strikeCreateQuoteByInvoice',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// prettier-ignore
					'Authorization': 'Bearer ' + TOKEN,
				},
				body: JSON.stringify({
					lnInvoice: lnInvoice,
				}),
			}
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};
