import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
	userUsername: '',
	userEmail: '',
	userFirstName: '',
	userLastName: '',
	userPhone: '',
	userRole: '',
	userLightningAddress: '',
	userBalanceAvailable: '',
	userBalancePending: '',
	userBalanceEarned: '',
	userBalanceTotal: '',
	userNotifications: [],
	userNotificationsCount: 0,
	userReferralCode: '',
	userReferrals: 0,
};

const userSlice = createSlice({
	name: 'user',
	initialState: initialUserState,
	reducers: {
		setUserData(state, action) {
			state.userUsername = action.payload.userUsername;
			state.userFirstName = action.payload.userFirstName;
			state.userLastName = action.payload.userLastName;
			state.userEmail = action.payload.userEmail;
			state.userPhone = action.payload.userPhone;
			state.userRole = action.payload.userRole;
			state.userLightningAddress = action.payload.userLightningAddress;
			state.userReferralCode = action.payload.userReferralCode;
			state.userReferrals = action.payload.userReferrals;
		},
		setUserBalance(state, action) {
			state.userBalanceAvailable = action.payload.userBalanceAvailable;
			state.userBalancePending = action.payload.userBalancePending;
			state.userBalanceEarned = action.payload.userBalanceEarned;
			state.userBalanceTotal = action.payload.userBalanceTotal;
		},
		setUserNotifications(state, action) {
			state.userNotifications = action.payload.userNotifications;
			state.userNotificationsCount = action.payload.userNotificationsCount;
		},
		subtractAndAddBalance(state, action) {
			const amount = action.payload.amountTransferred;
			state.userBalanceAvailable -= amount || 0;
			state.userBalanceTotal -= amount || 0;
			state.userBalanceEarned += amount || 0;
		},
	},
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
