// Chakra imports
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { addLink } from 'api/link';
import ResultMessage from 'components/modal/ResultMessage';
import { ALERT } from 'constant/messages';
import { useState } from 'react';
import { IoMdLink } from 'react-icons/io';

// Assets
import { MdOutlineAttachMoney } from 'react-icons/md';
import { RiShoppingCart2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';

export default function Summary(props) {
	const { brand, setLinkBuilder } = props;
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const ROLEID = useSelector((state) => state.user.userRole);

	const isAllowed = ROLEID && brand?.allowedRoles?.includes(ROLEID.toString());

	const primaryBtnTextColor = useColorModeValue('gray.100', 'gray.900');
	const primaryBtnBgColor = useColorModeValue('brand.500', 'gray.100');
	const secondaryBtnTextColor = useColorModeValue('gray.900', 'gray.100');
	const secondaryBtnBgColor = useColorModeValue('background.100', 'gray.500');
	const secondaryBtnBorderColor = useColorModeValue('brand.500', 'gray.100');

	const textColor = useColorModeValue('gray.900', 'gray.100');
	const shadow = useColorModeValue(
		' 0px 50px 40px -34px rgba(112, 144, 176, 0.16)',
		'unset'
	);
	const borderColor = useColorModeValue('secondaryGray.400', 'transparent');
	const cardBg = useColorModeValue('background.100', 'gray.500');

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState({
		state: false,
		title: '',
		subtitle: '',
	});

	const handleSubmit = async (affiliateLink, uniqueBumbeiId) => {
		try {
			setLoading(true);

			let linkObj = {
				link: affiliateLink,
				advertiserId: brand?.advertiserId,
				networkId: brand?.networkId,
				userId: USERID,
				brandId: brand?.id,
				domain: brand?.domain,
				bumbeiId: uniqueBumbeiId,
				status: 'activo',
				type: 'internal',
			};
			const response = await addLink(TOKEN, USERID, linkObj);
			const result = await response.json();

			if (!response.status === 200 || !result.status === 'SUCCESS') {
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			}
		} catch (error) {
			console.error(error);
			handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
		} finally {
			setLoading(false);
		}
	};

	const handleBuyAction = () => {
		function generateUniqueId() {
			return Date.now();
		}

		let timeStamp = generateUniqueId();
		let concatenatedString = USERID + timeStamp.toString();
		let uniqueBumbeiId = Number(concatenatedString);
		if (brand.link) {
			let affiliateLink = brand.link
				.replace('{user}', uniqueBumbeiId)
				.replace('{encoded_url}', brand.domain);

			handleSubmit(affiliateLink, uniqueBumbeiId);
			window.open(affiliateLink, '_blank');
		} else {
			handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
		}
	};

	const handleLinkBuilderAction = () => {
		setLinkBuilder({
			state: true,
			title: 'Crea tu enlace personalizado',
			subtitle: `Deja un enlace de cualquier producto de ${brand.brandName} y lo convertiremos en un enlace de Bumbei para que generes cashback.`,
			brand: brand,
		});
	};

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	return (
		<Flex
			direction='column'
			ps={{ base: 'unset', lg: "'65px'" }}
			mx='auto'
			maxW={{ base: '100%', md: 'max-content' }}
		>
			<Text
				color={textColor}
				fontSize={{ base: '36px', '2xl': '54px' }}
				fontWeight='700'
				mb='-30px'
				lineHeight='100%'
			>
				Detalles
			</Text>
			<Flex
				mt='75px'
				mb='25px'
				justifyContent='space-around'
				alignItems='center'
			>
				<Flex justifyContent='center' alignItems='center'>
					<InfoOutlineIcon
						h={{ base: '15px', md: '20px' }}
						w={{ base: '15px', md: '20px' }}
						me='15px'
					/>
					<Box>
						<Text color={textColor} fontSize='md' fontWeight='500'>
							Categoría
						</Text>
						<Flex align='center'>
							<Text color={textColor} fontSize='lg' fontWeight='700' me='5px'>
								{brand?._categoryId?.name}
							</Text>
						</Flex>
					</Box>
				</Flex>
				<Flex justifyContent='center' alignItems='center'>
					<Icon
						as={MdOutlineAttachMoney}
						h={{ base: '23px' }}
						w={{ base: '23px' }}
						me='15px'
					/>

					<Box>
						<Text color={textColor} fontSize='md' fontWeight='500'>
							Cashback
						</Text>
						<Flex align='center'>
							<Text color={textColor} fontSize='lg' me='6px' fontWeight='700'>
								{`${brand.cashback}%`}
							</Text>
						</Flex>
					</Box>
				</Flex>
			</Flex>
			<Flex
				w='100%'
				align='center'
				p='40px'
				direction='column'
				border='1px solid'
				borderColor={borderColor}
				boxShadow={shadow}
				bg={cardBg}
				borderRadius='20px'
				mb='50px'
			>
				<Text fontWeight='500' color={textColor} fontSize='22px'>
					Tú eliges.
				</Text>
				<Text
					mt='10px'
					mb='30px'
					maxW='350px'
					color={textColor}
					fontSize='md'
					fontWeight='500'
					textAlign='center'
				>
					{isAllowed
						? 'Compra y recibe reembolsos en Bitcoin a cambio, o genera tu propio enlace personalizado y compártelo con tu comunidad y amigos para ganar.'
						: 'Compra y recibe reembolsos en Bitcoin a cambio.'}
				</Text>

				<Flex
					flexDirection={{ sm: 'column', xl: 'row' }}
					gap={'1rem'}
					mb={{ base: '0px', md: '30px' }}
					w={'100%'}
				>
					<Button
						px='15px'
						variant='brand'
						fontSize='sm'
						fontWeight='500'
						h='46px'
						transition='all 0.3s'
						_hover={{ transform: 'scale(1.05)' }}
						onClick={handleBuyAction}
						w={{ sm: '100%' }}
						isLoading={loading}
						color={primaryBtnTextColor}
						bg={primaryBtnBgColor}
					>
						Compra
						<Icon
							width='1rem'
							height='1rem'
							as={RiShoppingCart2Line}
							color={primaryBtnTextColor}
							ml='0.5rem'
						/>
					</Button>
					{isAllowed && (
						<Button
							px='15px'
							variant='brand'
							fontSize='sm'
							fontWeight='500'
							h='46px'
							transition='all 0.3s'
							_hover={{ transform: 'scale(1.05)' }}
							onClick={handleLinkBuilderAction}
							w={{ sm: '100%' }}
							color={secondaryBtnTextColor}
							bg={secondaryBtnBgColor}
							borderColor={secondaryBtnBorderColor}
							border={'2px solid'}
						>
							Genera tu enlace
							<Icon
								color={secondaryBtnTextColor}
								width='1rem'
								height='1rem'
								as={IoMdLink}
								ml='0.5rem'
							/>
						</Button>
					)}
				</Flex>
			</Flex>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Flex>
	);
}
