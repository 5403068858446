// Chakra Imports
import {
	Box,
	Flex,
	Icon,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
// Assets
import {
	countPendingNotifications,
	getAllNotifications,
	setAllAsViewed,
	setAsViewed,
} from 'api/notification';

import NotificationCount from 'components/navbar/components/NotificationCount';
import Configurator from 'components/navbar/Configurator';
import { useEffect, useState } from 'react';
import { FaRegUser } from 'react-icons/fa6';
import { MdNotificationsNone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import routes from 'routes';
import { userActions } from 'store/user';
import { socket } from '../../../src/socket/socket';

export default function HeaderLinks(props) {
	const { secondary, theme, setTheme } = props;

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const navbarIcon = useColorModeValue('gray.900', 'gray.100');
	let menuBg = useColorModeValue('gray.100', 'gray.900');
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const hoverTextColor = useColorModeValue('gray.900', 'gray.900');
	const textBalanceColor = useColorModeValue('gray.100', 'gray.900');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const btcColor = useColorModeValue('brand.700', 'gray.100');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	const {
		userFirstName,
		userBalanceTotal,
		userNotificationsCount,
		userNotifications,
	} = useSelector((state) => state.user);

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [mxnValue, setMxnValue] = useState(0);
	const [usdValue, setUsdValue] = useState(0);
	const [currentCurrency, setCurrentCurrency] = useState('SATS');

	useEffect(() => {
		fetchNotifications();
	}, []);

	useEffect(() => {
		const fetchConversionRates = async () => {
			try {
				const response = await fetch(
					'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=mxn,usd'
				);
				const data = await response.json();
				const conversionRateMxn = data.bitcoin.mxn;
				const conversionRateUsd = data.bitcoin.usd;
				setMxnValue(conversionRateMxn);
				setUsdValue(conversionRateUsd);
			} catch (error) {
				console.error('Error fetching conversion rates:', error);
			}
		};

		fetchConversionRates();
	}, []);

	const satoshiToMxn = (sats) => {
		const btcValue = sats / 100_000_000;
		const mxnValueFormatted = (btcValue * mxnValue).toFixed(2);
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(mxnValueFormatted);
	};

	const satoshiToUsd = (sats) => {
		const btcValue = sats / 100_000_000;
		const usdValueFormatted = (btcValue * usdValue).toFixed(2);
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(usdValueFormatted);
	};

	const satoshiToBtc = (sats) => {
		return (sats / 100_000_000).toFixed(8);
	};

	const handleCurrencyChange = () => {
		if (currentCurrency === 'SATS') {
			setCurrentCurrency('USD');
		} else if (currentCurrency === 'USD') {
			setCurrentCurrency('BTC');
		} else if (currentCurrency === 'MXN') {
			setCurrentCurrency('SATS');
		} else {
			setCurrentCurrency('MXN');
		}
	};

	socket.on('event:notification', (notification) => {
		dispatch((dispatch, getState) => {
			const { userNotifications } = getState().user;
			const updatedNotifications = [...userNotifications, notification];

			const newNotificationsCount = updatedNotifications.filter(
				(notif) => !notif.viewed
			).length;

			dispatch(
				userActions.setUserNotifications({
					userNotificationsCount: newNotificationsCount,
					userNotifications: updatedNotifications,
				})
			);
		});
	});

	socket.on('event:userBalanceUpdate', (user) => {
		dispatch(
			userActions.setUserBalance({
				userBalanceAvailable: (user.balanceAvailable ?? 0).toFixed(0),
				userBalancePending: (user.balancePending ?? 0).toFixed(0),
				userBalanceEarned: (user.balanceEarned ?? 0).toFixed(0),
				userBalanceTotal: (user.balanceTotal ?? 0).toFixed(0),
			})
		);
	});

	const handleSetAllAsViewed = () => {
		setAllAsViewed(TOKEN, USERID);
		// Update all notifications to have viewed as false
		const updatedNotifications = userNotifications.map((notification) => ({
			...notification,
			viewed: true,
		}));

		dispatch(
			userActions.setUserNotifications({
				userNotificationsCount: 0,
				userNotifications: updatedNotifications,
			})
		);
	};

	const handleSetAsViewed = (ID) => {
		setAsViewed(TOKEN, ID);
		const updatedNotifications = userNotifications.map((notification) =>
			notification.id === ID ? { ...notification, viewed: true } : notification
		);
		const newNotificationsCount = updatedNotifications.filter(
			(notif) => !notif.viewed
		).length;

		dispatch(
			userActions.setUserNotifications({
				userNotificationsCount: newNotificationsCount - 1,
				userNotifications: updatedNotifications,
			})
		);
	};

	const fetchNotifications = async () => {
		setLoading(true);
		try {
			const notifications = await getAllNotifications(TOKEN, USERID);
			const totalNotifications = await countPendingNotifications(TOKEN, USERID);
			dispatch(
				userActions.setUserNotifications({
					userNotificationsCount: totalNotifications,
					userNotifications: notifications,
				})
			);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='999px'
			boxShadow={shadow}
			justifyContent={{ sm: 'space-around' }}
		>
			<Flex
				bg={btcColor}
				display={{ base: 'none', md: 'flex' }}
				borderRadius='20px'
				ms='auto'
				p='6px'
				align='center'
				me='6px'
				ml={{ sm: 0 }}
				cursor={'pointer'}
				onClick={handleCurrencyChange}
			>
				<Text
					pl={2}
					w='max-content'
					color={textBalanceColor}
					fontSize='sm'
					fontWeight='500'
					me='6px'
				>
					Balance total:
				</Text>
				<Text
					pr={1}
					w='max-content'
					color={textBalanceColor}
					fontSize='sm'
					fontWeight='700'
					me='6px'
				>
					{currentCurrency === 'SATS' &&
						`${
							Intl.NumberFormat('en-US', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}).format(userBalanceTotal) || '0'
						} sats`}
					{currentCurrency === 'USD' &&
						`$${satoshiToUsd(userBalanceTotal)} USD`}
					{currentCurrency === 'BTC' && `${satoshiToBtc(userBalanceTotal)} BTC`}
					{currentCurrency === 'MXN' &&
						`$${satoshiToMxn(userBalanceTotal)} MXN`}
				</Text>
			</Flex>

			<SidebarResponsive routes={routes} />
			<Menu closeOnSelect={false}>
				<MenuButton p='0px' mr={2} onClick={fetchNotifications}>
					<Icon
						mt='6px'
						as={MdNotificationsNone}
						color={navbarIcon}
						w='18px'
						h='18px'
						me='10px'
					/>
					{userNotificationsCount > 0 && (
						<Box
							position='absolute'
							top={{ base: '40px', md: '10px' }}
							ml={1}
							bg='blue.500'
							color='gray.100'
							borderRadius='50%'
							px={2.5}
							py={0.8}
							fontSize='0.6rem'
							fontWeight='bold'
							display='flex'
							alignItems='center'
							justifyContent='center'
						>
							{userNotificationsCount > 99 ? '99+' : userNotificationsCount}
						</Box>
					)}
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='20px'
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}
					maxH={{ base: '70vh' }}
					overflowY={{ base: 'scroll' }}
				>
					<Flex w='100%' mb='20px'>
						<NotificationCount
							textColor={textColor}
							notificationsCount={userNotificationsCount}
						/>

						{userNotificationsCount > 0 && (
							<Text
								fontSize='sm'
								fontWeight='500'
								color={textColorBrand}
								ms='auto'
								cursor='pointer'
								onClick={handleSetAllAsViewed}
							>
								Marcar todas como leídas
							</Text>
						)}
					</Flex>
					<Flex flexDirection='column'>
						{!loading &&
							userNotifications?.length > 0 &&
							userNotifications?.map((notification) => (
								<MenuItem
									key={notification.id}
									bg='inherit'
									_hover={{ bg: btcColor, color: hoverTextColor }}
									_focus={{ bg: btcColor, color: hoverTextColor }}
									p='10px'
									borderRadius='8px'
									mb='10px'
								>
									<ItemContent
										notification={notification}
										handleSetAsViewed={() => handleSetAsViewed(notification.id)}
									/>
								</MenuItem>
							))}
						{!loading && userNotifications?.length === 0 && (
							<MenuItem
								_hover={{ bg: 'none' }}
								_focus={{ bg: 'none' }}
								px='0'
								borderRadius='8px'
								mb='10px'
							>
								<Flex
									w='100%'
									alignItems='center'
									justifyContent='center'
									h='40vh'
								>
									<Text textAlign='center'>
										No tienes notificaciones por el momento.
									</Text>
								</Flex>
							</MenuItem>
						)}
						{loading && (
							<Flex alignItems='center' justifyContent='center' h='40vh'>
								<Spinner color={textColorBrand} />
							</Flex>
						)}
					</Flex>
				</MenuList>
			</Menu>

			<Configurator
				mini={props.mini}
				setMini={props.setMini}
				theme={theme}
				setTheme={setTheme}
			/>

			<Menu>
				<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={FaRegUser}
						color={navbarIcon}
						w='15px'
						h='15px'
						me='10px'
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='0px'
					mt='10px'
					borderRadius='20px'
					bg={menuBg}
					opacity='unset'
					border='none'
				>
					<Flex w='100%' mb='0px'>
						<Text
							ps='20px'
							pt='16px'
							pb='10px'
							w='100%'
							borderBottom='1px solid'
							borderColor={borderColor}
							fontSize='sm'
							fontWeight='700'
							color={textColor}
						>
							👋&nbsp; Hola, {userFirstName}
						</Text>
					</Flex>
					<Flex flexDirection='column' p='10px'>
						<NavLink to={'/client/profile'}>
							<MenuItem
								bg='inherit'
								_hover={{ bg: btcColor, color: hoverTextColor }}
								borderRadius='8px'
								px='14px'
							>
								<Text fontSize='sm'>Perfil</Text>
							</MenuItem>
						</NavLink>

						<MenuItem
							bg='inherit'
							_hover={{ bg: btcColor, color: hoverTextColor }}
							color='red.400'
							borderRadius='8px'
							px='14px'
						>
							<Link href='/auth'>
								<Text fontSize='sm'>Cerrar sesión</Text>
							</Link>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}
