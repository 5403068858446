// Chakra imports
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	Flex,
	FormControl,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { changePassword, verifyPassword } from 'api/user';
import Card from 'components/card/Card.js';
import InputField from 'components/fields/InputField';
import { ERROR, SUCCESS } from 'constant/messages';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { validatePassword } from 'utils/format';

export default function Password(props) {
	const { ...rest } = props;

	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);

	const brandColor = useColorModeValue('brand.500', 'gray.100');
	const inputBorder = useColorModeValue('gray.900', 'gray.100');
	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');

	const [isLoading, setIsLoading] = useState(false);

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [isValid, setIsValid] = useState({
		currentPassword: true,
		newPassword: true,
		confirmPassword: true,
	});

	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const handleMessage = (status, description) => {
		setMessage({
			state: true,
			status: status,
			description: description,
		});
	};

	const handleUpdatePassword = async () => {
		try {
			setIsLoading(true);

			if (newPassword !== confirmPassword) {
				handleMessage('error', ERROR.CONFIRM_PASSWORD);
				setIsValid((prevState) => ({
					...prevState,
					newPassword: false,
					confirmPassword: false,
				}));
				return;
			}
			if (newPassword === currentPassword) {
				handleMessage('error', ERROR.SAME_PASSWORD);
				setIsValid((prevState) => ({
					...prevState,
					newPassword: false,
					currentPassword: false,
				}));
				return;
			}
			const isNewPasswordValid = validatePassword(newPassword);
			if (!isNewPasswordValid) {
				handleMessage('error', ERROR.VALID_PASSWORD);
				setIsValid((prevState) => ({
					...prevState,
					newPassword: false,
					confirmPassword: false,
				}));
				return;
			}

			const responseCurr = await verifyPassword(TOKEN, USERID, currentPassword);
			const resultCurr = await responseCurr.json();

			if (responseCurr.status === 200 && resultCurr.status === 'SUCCESS') {
				const responseChange = await changePassword(
					TOKEN,
					USERID,
					currentPassword,
					newPassword
				);
				const resultChange = await responseChange.json();

				if (
					responseChange.status === 200 &&
					resultChange.status === 'SUCCESS'
				) {
					handleMessage('success', SUCCESS.PASSWORD_UPDATED);
					setCurrentPassword('');
					setNewPassword('');
					setConfirmPassword('');
				} else {
					handleMessage('error', ERROR.SOMETHING_WRONG);
				}
			} else {
				handleMessage('error', ERROR.CURRENT_PASSWORD);
				setIsValid((prevState) => ({
					...prevState,
					currentPassword: false,
				}));
				return;
			}
		} catch (error) {
			console.error(error);
			handleMessage('error', ERROR.SOMETHING_WRONG);
		} finally {
			setIsLoading(false);
		}
	};

	const handleFocus = () => {
		setMessage({
			state: false,
			status: '',
			description: '',
		});
		setIsValid({
			currentPassword: true,
			newPassword: true,
			confirmPassword: true,
		});
	};

	return (
		<Card bg={cardBg} mb='30px' {...rest}>
			<Flex direction='column' mb='30px' ms='10px'>
				<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
					Cambiar contraseña
				</Text>
				<Text fontSize='md' color={textColorSecondary}>
					Aquí puedes establecer tu nueva contraseña
				</Text>
				{!isLoading && message.state && (
					<Alert mt='15px' status={message.status || 'error'}>
						<AlertIcon />
						<AlertDescription>{message.description}</AlertDescription>
					</Alert>
				)}
			</Flex>
			<FormControl>
				<Flex flexDirection='column'>
					<InputField
						borderColor={
							message.status === 'error' && !isValid.currentPassword
								? 'red.500'
								: inputBorder
						}
						borderWidth={
							message.status === 'error' && !isValid.currentPassword ? 2 : null
						}
						bg={cardBg}
						mb='25px'
						id='old'
						label='Contraseña actual'
						placeholder='Tu contraseña actual'
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						value={currentPassword}
						onChange={(e) => {
							setCurrentPassword(e.target.value);
						}}
						onFocus={handleFocus}
					/>
					<InputField
						borderColor={
							message.status === 'error' && !isValid.currentPassword
								? 'red.500'
								: inputBorder
						}
						bg={cardBg}
						borderWidth={
							message.status === 'error' && !isValid.newPassword ? 2 : null
						}
						mb='25px'
						id='new'
						label='Nueva contraseña'
						placeholder='Tu nueva contraseña'
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						value={newPassword}
						onChange={(e) => {
							setNewPassword(e.target.value);
						}}
						onFocus={handleFocus}
					/>
					<InputField
						borderColor={
							message.status === 'error' && !isValid.currentPassword
								? 'red.500'
								: inputBorder
						}
						bg={cardBg}
						borderWidth={
							message.status === 'error' && !isValid.confirmPassword ? 2 : null
						}
						mb='25px'
						id='new'
						label='Confirma tu nueva contraseña'
						placeholder='Confirma tu nueva contraseña'
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						value={confirmPassword}
						onChange={(e) => {
							setConfirmPassword(e.target.value);
						}}
						onFocus={handleFocus}
					/>
				</Flex>
			</FormControl>
			<Button
				isDisabled={!currentPassword || !newPassword}
				isLoading={isLoading}
				borderRadius='16px'
				minW='183px'
				h='44px'
				ms='auto'
				mt='33px'
				variant='brand'
				fontSize='sm'
				fontWeight='500'
				onClick={handleUpdatePassword}
			>
				Cambiar contraseña
			</Button>
		</Card>
	);
}
