// Chakra Imports
import {
	Button,
	Flex,
	Radio,
	useColorModeValue,
	useRadio,
} from '@chakra-ui/react';
// Assets
export default function ConfiguratorRadio(props) {
	const borderButton = useColorModeValue('gray.500', 'gray.100');
	const activeShadow = useColorModeValue(
		'0px 18px 40px rgba(112, 144, 176, 0.22)',
		'none'
	);
	const Bg = useColorModeValue('gray.100', 'gray.900');
	const activeBg = useColorModeValue('#F7F9FF', 'gray.100Alpha.100');
	//eslint-disable-next-line
	const { getInputProps, getCheckboxProps } = useRadio(props);
	const input = getInputProps();
	return (
		<Button
			h='max-content'
			py='16px'
			display={'flex'}
			flexDirection='column'
			bg={props.active === true ? Bg : 'transparent'}
			boxShadow={props.active === true ? activeShadow : 'none'}
			_hover={{ background: Bg, boxShadow: activeShadow }}
			_focus={{ background: Bg, boxShadow: activeShadow }}
			_active={{ background: activeBg, boxShadow: activeShadow }}
			onClick={props.onClick}
			as='label'
			px={{ base: '10px', md: 'none' }}
		>
			<input {...input} />
			<Flex w='100%' justifyContent={'space-between'} mb='10px'>
				{props.label}
				<Radio
					borderColor={borderButton}
					colorScheme='brand'
					isChecked={props.active}
				/>
			</Flex>
			{props.children}
		</Button>
	);
}
