import React from 'react';

// chakra imports
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Icon,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import {
	renderThumb,
	renderTrack,
	renderView,
} from 'components/scrollbar/Scrollbar';
import Content from 'components/sidebar/components/Content';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Assets
import { IoMenuOutline } from 'react-icons/io5';

function Sidebar(props) {
	const { routes, mini, hovered, setHovered } = props;
	// this is for the rest of the collapses
	let variantChange = '0.2s linear';
	let shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
		'unset'
	);
	// Chakra Color Mode
	let sidebarBg = useColorModeValue('#E9E9DF', 'gray.900');
	let sidebarRadius = '30px';
	let sidebarMargins = '0px';
	// SIDEBAR
	return (
		<Box
			display={{ sm: 'none', xl: 'block' }}
			position='fixed'
			minH='100%'
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Box
				bg={sidebarBg}
				transition={variantChange}
				w={
					mini === false
						? '270px'
						: mini === true && hovered === true
						? '270px'
						: '120px'
				}
				ms={{
					sm: '16px',
				}}
				my={{
					sm: '16px',
				}}
				h='calc(100vh - 32px)'
				m={sidebarMargins}
				borderRadius={sidebarRadius}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}
			>
				<Content mini={mini} hovered={hovered} routes={routes} />
			</Box>
		</Box>
	);
}

export function SidebarResponsive(props) {
	let sidebarBackgroundColor = useColorModeValue('gray.100', 'gray.900');
	let menuColor = useColorModeValue('gray.900', 'gray.100');

	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const { routes } = props;
	return (
		<Flex display={{ sm: 'flex', xl: 'none' }} alignItems='center'>
			<Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
				<Icon
					as={IoMenuOutline}
					color={menuColor}
					my='auto'
					w='20px'
					h='20px'
					me='10px'
					_hover={{ cursor: 'pointer' }}
				/>
			</Flex>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent
					w='295px'
					maxW='295px'
					ms={{
						sm: '16px',
					}}
					my={{
						sm: '16px',
					}}
					borderRadius='16px'
					bg={sidebarBackgroundColor}
				>
					<DrawerCloseButton
						zIndex='3'
						onClick={onClose}
						_focus={{ boxShadow: 'none' }}
						_hover={{ boxShadow: 'none' }}
					/>
					<DrawerBody maxW='295px' px='0rem' pb='0'>
						<Scrollbars
							autoHide
							renderTrackVertical={renderTrack}
							renderThumbVertical={renderThumb}
							renderView={renderView}
						>
							<Content mini={false} routes={routes} />
						</Scrollbars>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}
// PROPS

export default Sidebar;
