import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
		brand: {
			50: '#FFE8E2',
			100: '#FFE8E2',
			200: '#FFC7BA',
			300: '#FFA28E',
			400: '#FF7D62',
			500: '#FC6A42',
			600: '#FC6A42',
			700: '#FC6A42',
			800: '#FC6A42',
			900: '#FC6A42',
		},
		brandScheme: {
			50: '#EFEBFF',
			100: '#E9E3FF',
			200: '#7551FF',
			300: '#7551FF',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A',
		},
		brandTabs: {
			50: '#EFEBFF',
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#422AFB',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A',
		},
		secondaryGray: {
			100: '#E0E5F2',
			200: '#E1E9F8',
			300: '#F4F7FE',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559',
		},
		red: {
			100: '#FEEFEE',
			500: '#EE5D50',
			600: '#E31A1A',
		},
		blue: {
			50: '#EFF4FB',
			500: '#3965FF',
		},
		orange: {
			100: '#FFF6DA',
			500: '#FFB547',
		},
		green: {
			100: '#E6FAF5',
			500: '#01B574',
		},
		navy: {
			100: '#E8F0FF',
			200: '#C7D9FF',
			300: '#A2BEFF',
			400: '#7DA3FF',
			500: '#6082DB',
			600: '#4B66B8',
			700: '#354C96',
			800: '#354C96',
			900: '#141F55',
		},
		gray: {
			100: '#E3E3D7',
			500: '#4548454D',
			900: '#151615',
		},
		background: {
			100: '#E9E9DF',
			900: '#4B66B8',
		},
	},
	styles: {
		global: (props) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('background.100', 'gray.900')(props),
				fontFamily: 'Inter',
				letterSpacing: '-0.5px',
			},
			input: {
				color: 'gray.700',
			},
			html: {
				fontFamily: 'Inter',
			},
			heading: {
				fontFamily: `'Unbounded', sans-serif`,
			},
		}),
	},
};
