import API_URL from '../config';

export const getAllBrand = async (TOKEN) => {
	try {
		const response = await fetch(API_URL + 'client/api/v1/brand/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				options: {
					paginate: 100,
					sort: { createdAt: 1 },
					where: { isActive: true },
					include: [
						{
							model: 'Category',
							attributes: ['name'],
							as: '_categoryId',
						},
					],
				},
				isCountOnly: false,
			}),
		});
		return response;
	} catch (error) {
		console.error('Error:', error);
	}
};
