import {
	Badge,
	Button,
	Flex,
	FormLabel,
	Input,
	useColorModeValue,
} from '@chakra-ui/react';

export default function LinkForm({
	link,
	message,
	setLink,
	handleSubmit,
	loading,
}) {
	const textColor = useColorModeValue('white', 'gray.900');
	return (
		<Flex direction='column' gap={5}>
			{message.state && (
				<Badge
					mt={5}
					mb={3}
					w='100%'
					colorScheme={message.status}
					textTransform='unset'
					textAlign='center'
					fontSize='md'
					whiteSpace='normal'
					lineHeight={1.5}
				>
					{message.description}
				</Badge>
			)}
			{message.status !== 'red' && link.customLink && (
				<Flex direction='column' gap={5}>
					<Flex pb={3} direction='column'>
						<FormLabel display='flex' alignItems='center' gap='0.5rem'>
							Campaña (opcional)
						</FormLabel>
						<Input
							borderWidth={2}
							value={link.campaign}
							onChange={(e) =>
								setLink((prevState) => ({
									...prevState,
									campaign: e.target.value,
								}))
							}
							borderRadius='6px'
							height='40px'
							isRequired={true}
							fontSize='md'
							placeholder='Campaña'
							variant='auth'
							size='lg'
						/>
					</Flex>
					<Flex pb={3} direction='column'>
						<FormLabel display='flex' alignItems='center' gap='0.5rem'>
							Identificador para rastreo (opcional)
						</FormLabel>
						<Input
							borderWidth={2}
							value={link.tracker}
							onChange={(e) =>
								setLink((prevState) => ({
									...prevState,
									tracker: e.target.value,
								}))
							}
							borderRadius='6px'
							height='40px'
							isRequired={true}
							fontSize='md'
							placeholder='Identificador'
							variant='auth'
							size='lg'
						/>
					</Flex>
					<Flex pb={3} direction='column' alignItems='center'>
						<Button
							isLoading={loading}
							mt={3}
							mb={3}
							px='35px'
							variant='brand'
							fontSize='sm'
							fontWeight='500'
							h='46px'
							transition='all 0.3s'
							_hover={{ transform: 'scale(1.05)' }}
							onClick={handleSubmit}
							color={textColor}
						>
							Generar enlace
						</Button>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
}
