// Chakra imports
import {
	Avatar,
	Button,
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import profileAvatar from 'assets/icon/bumbei_iconoapp_RGB-01.png';
import Card from 'components/card/Card.js';
import WithdrawalModal from 'components/modal/withdrawal/WithdrawalModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Profile(props) {
	const { ...rest } = props;
	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');
	const [withdrawalModal, setWithdrawalModal] = useState(false);

	const handleWithdrawalModal = () => {
		setWithdrawalModal(true);
	};

	const handleCloseModal = () => {
		setWithdrawalModal(false);
	};

	const {
		userFirstName,
		userLastName,
		userEmail,
		userBalanceAvailable,
		userLightningAddress,
	} = useSelector((state) => state.user);

	return (
		<Card bg={cardBg} mb='20px' {...rest}>
			<Flex direction='column' align='start'>
				<Flex direction='row' align='center'>
					<Avatar src={profileAvatar} h='87px' w='87px' me='10px' />
					<Flex direction='column'>
						<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
							{userFirstName} {userLastName}
						</Text>
						<Text
							overflowWrap='anywhere'
							mt='1px'
							color={textColorSecondary}
							fontSize='md'
						>
							{userEmail}
						</Text>
					</Flex>
				</Flex>
				<Flex direction='column'>
					<Text
						ml='10px'
						mt='20px'
						color={textColorPrimary}
						fontWeight='bold'
						fontSize='2xl'
					>
						Balance disponible: {`${userBalanceAvailable || 0} SATS`}
					</Text>
				</Flex>
			</Flex>
			{userBalanceAvailable > 0 && (
				<Button
					onClick={handleWithdrawalModal}
					isDisabled={!userBalanceAvailable}
					borderRadius='16px'
					minW='183px'
					h='44px'
					ms='auto'
					mt='33px'
					variant={'brand'}
					fontSize='sm'
					fontWeight='500'
				>
					Retirar
				</Button>
			)}
			<WithdrawalModal
				isOpen={withdrawalModal}
				onClose={handleCloseModal}
				userBalanceAvailable={userBalanceAvailable}
				userLightningAddress={userLightningAddress}
			/>
		</Card>
	);
}
