import API_URL from '../config';

export const login = async (email, password) => {
	try {
		const response = await fetch(API_URL + 'client/auth/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: email,
				password: password,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const signUp = async (user) => {
	try {
		const response = await fetch(API_URL + 'client/auth/register', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: user.email,
				email: user.email,
				password: user.password,
				referralCode: user.referralCode,
				firstName: user.firstName,
				lastName: user.lastName,
				role: 1,
				createdAt: new Date(),
			}),
		});
		return response;
	} catch (error) {
		console.error(error);
	}
};

export const forgotPassword = async (email) => {
	try {
		const response = await fetch(API_URL + 'client/auth/forgot-password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const resetPassword = async (code, newPassword) => {
	try {
		const response = await fetch(API_URL + 'client/auth/reset-password', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				code: code,
				newPassword: newPassword,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const validateOtp = async (otp) => {
	try {
		const response = await fetch(API_URL + 'client/auth/validate-otp', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				otp: otp,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const updateProfile = async (TOKEN, user) => {
	try {
		const response = await fetch(API_URL + 'client/user/partial-update', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				username: user.username,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				lightningAddress: user.lightningAddress,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const verifyPassword = async (TOKEN, USERID, password) => {
	try {
		const response = await fetch(API_URL + 'client/auth/verify-password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				//prettier-ignore
				Authorization: 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				userId: USERID,
				password: password,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const changePassword = async (
	TOKEN,
	USERID,
	oldPassword,
	newPassword
) => {
	try {
		const response = await fetch(API_URL + 'client/auth/change-password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				//prettier-ignore
				Authorization: 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				userId: USERID,
				oldPassword: oldPassword,
				newPassword: newPassword,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const updatePushNotificationToken = async (TOKEN, pushToken) => {
	try {
		const response = await fetch(API_URL + 'client/user/partial-update', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + TOKEN,
			},
			body: JSON.stringify({
				pushToken: pushToken,
			}),
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};
