import { mode } from '@chakra-ui/theme-tools';
export const textareaStyles = {
	components: {
		Textarea: {
			baseStyle: {
				field: {
					fontWeight: 400,
					borderRadius: '8px',
				},
			},

			variants: {
				main: (props) => ({
					field: {
						bg: mode('transparent', 'gray.900')(props),
						border: '1px solid !important',
						color: mode('gray.900', 'gray.100')(props),
						borderColor: mode('secondaryGray.100', 'gray.100Alpha.100')(props),
						borderRadius: '16px',
						fontSize: 'sm',
						p: '20px',
						_placeholder: { color: 'secondaryGray.400' },
					},
				}),
				auth: (props) => ({
					field: {
						bg: 'gray.100',
						border: '1px solid',
						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'gray.900' },
					},
				}),
				authSecondary: (props) => ({
					field: {
						bg: 'gray.100',
						border: '1px solid',

						borderColor: 'secondaryGray.100',
						borderRadius: '16px',
						_placeholder: { color: 'gray.900' },
					},
				}),
				search: (props) => ({
					field: {
						border: 'none',
						py: '11px',
						borderRadius: 'inherit',
						_placeholder: { color: 'gray.900' },
					},
				}),
			},
		},
	},
};
