import { Icon } from '@chakra-ui/react';
import { MdLock, MdWallet } from 'react-icons/md';

// Auth Imports
import { LinkIcon } from '@chakra-ui/icons';
import { MdLogout } from 'react-icons/md';
import { RxDashboard, RxHome, RxPerson } from 'react-icons/rx';
import ForgotPassword from 'views/auth/forgotPassword';
import SignIn from 'views/auth/signIn';
import SignUp from 'views/auth/signUp';
import Verification from 'views/auth/verification';
import BrandDashboard from 'views/client/brands';
import BrandDetail from 'views/client/brands/BrandDetail';
import Links from 'views/client/links';
import Profile from 'views/client/profile';
import Wallet from 'views/client/wallet';
import Welcome from 'views/client/welcome';

const routes = [
	// --- Client ---
	{
		name: 'Inicio',
		layout: '/client',
		path: '/home',
		icon: <Icon as={RxHome} width='20px' height='20px' color='inherit' />,
		component: <Welcome />,
	},
	{
		name: 'Marcas',
		layout: '/client',
		path: '/dashboard',
		icon: <Icon as={RxDashboard} width='20px' height='20px' color='inherit' />,
		component: <BrandDashboard />,
	},
	{
		name: 'Brand Detail',
		layout: '/client',
		path: '/dashboard/detail',
		icon: <Icon as={RxDashboard} width='20px' height='20px' color='inherit' />,
		component: <BrandDetail />,
		hide: true,
	},
	{
		name: 'Enlaces',
		layout: '/client',
		path: '/links',
		icon: <LinkIcon marginTop='-6px' />,
		component: <Links />,
	},
	{
		name: 'Cartera',
		layout: '/client',
		path: '/wallet',
		icon: <Icon as={MdWallet} width='20px' height='20px' color='inherit' />,
		component: <Wallet />,
	},
	{
		name: 'Perfil',
		layout: '/client',
		path: '/profile',
		icon: <Icon as={RxPerson} width='20px' height='20px' color='inherit' />,
		component: <Profile />,
	},
	// --- Authentication ---
	{
		name: 'Authentication',
		path: '/auth',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		collapse: true,
		items: [
			// --- Sign In ---
			{
				name: 'Sign In',
				layout: '/auth',
				path: '/sign-in',
				component: <SignIn />,
			},
			// --- Sign Up ---
			{
				name: 'Sign Up',
				path: '/sign-up',
				layout: '/auth',
				component: <SignUp />,
			},
			// --- Verification ---
			{
				name: 'Verification',
				layout: '/auth',
				path: '/verification',
				component: <Verification />,
			},
			// --- Forgot Password ---
			{
				name: 'Forgot Password',
				layout: '/auth',
				path: '/forgot-password',
				component: <ForgotPassword />,
			},
		],
	},
	// --- Logout ---
	{
		name: 'Logout',
		layout: '',
		path: '',
		icon: <Icon as={MdLogout} width='20px' height='20px' color='inherit' />,
		action: 'logout',
	},
];

export default routes;
