import {
	Badge,
	Box,
	Button,
	Card,
	Flex,
	Heading,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from '@chakra-ui/react';
import { getAllLink } from 'api/link';
import ResultMessage from 'components/modal/ResultMessage';
import CopyableChip from 'components/table/copyableChip';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/messages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDataType } from 'utils/format';
import { columnsDataLinks } from 'views/client/links/variable/columnsDataLinks';

export default function Links() {
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const navigate = useNavigate();
	const cardBalanceBg = useColorModeValue('background.100', 'gray.500');

	const [loading, setLoading] = useState(true);
	const [linkData, setLinkData] = useState([]);
	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchLinks = async () => {
			try {
				setLoading(true);

				const response = await getAllLink(TOKEN, USERID);
				const result = await response.json();

				if (response.status === 200 && result.status === 'SUCCESS') {
					const resultData = result?.data?.data || [];
					console.log(resultData);
					setLinkData(resultData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}
			} catch (error) {
				console.error(error);
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			} finally {
				setLoading(false);
			}
		};

		fetchLinks();
	}, []);

	// Check if the screen size is md or larger
	const isDesktop = useBreakpointValue({ base: false, md: true });

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Heading
				mb='20px'
				color={textColor}
				fontSize='2xl'
				ms='24px'
				fontWeight='700'
			>
				Tus enlaces Bumbei
			</Heading>

			{!loading && linkData.length > 0 && (
				<Box display={{ base: 'none', md: 'block' }}>
					<DataTable rows={linkData} columns={columnsDataLinks} />
				</Box>
			)}

			{!loading && linkData.length > 0 && (
				<Flex
					flexDirection='column'
					gap='1rem'
					my='1rem'
					display={{ base: 'flex', md: 'none' }}
					maxHeight='60vh'
					overflowY='auto'
				>
					{linkData.map(
						(x) =>
							x.shortLink && (
								<Card bgColor={cardBalanceBg} py='1rem' px='1rem' gap='1rem'>
									<Flex flexDirection='column'>
										<Flex
											gap='10px'
											align='center'
											mb='5px'
											justifyContent='space-between'
										>
											{x._brandId.brandName && (
												<Text fontWeight='bold'>{x._brandId.brandName}</Text>
											)}
											{x.status && (
												<Badge
													variant='subtle'
													colorScheme={
														x.status.toLowerCase() === 'activo'
															? 'green'
															: 'default'
													}
												>
													{x.status}
												</Badge>
											)}
										</Flex>

										<Text fontSize='sm'>
											{formatDataType('date', x.createdAt)}
										</Text>
									</Flex>

									<Flex width='-moz-fit-content'>
										<CopyableChip
											value={x.shortLink}
											copyable={true}
											style={{ fontSize: '13px' }}
										/>
									</Flex>
								</Card>
							)
					)}
				</Flex>
			)}

			{!loading && linkData.length === 0 && (
				<Flex
					flexDirection='column'
					p='3rem'
					justifyContent='center'
					alignItems='center'
				>
					<Text textAlign='center'>
						Aquí podrás encontrar todos los enlaces que generes con Bumbei.{' '}
						<br />
						¡Explora las marcas que tenemos para ti!
					</Text>
					<Button
						variant='brand'
						mt='25px'
						onClick={() => navigate('/client/dashboard')}
					>
						Explorar
					</Button>
				</Flex>
			)}

			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Flex>
	);
}
