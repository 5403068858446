// Chakra imports
import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import profileAvatar from 'assets/icon/bumbei_iconoapp_RGB-01.png';
import Card from 'components/card/Card.js';
import { useSelector } from 'react-redux';

export default function Profile(props) {
	const { ...rest } = props;
	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');

	const { userFirstName, userLastName, userEmail } = useSelector(
		(state) => state.user
	);

	return (
		<Card bg={cardBg} mb='20px' {...rest}>
			<Flex align='center'>
				<Avatar src={profileAvatar} h='87px' w='87px' me='10px' />
				<Flex direction='column'>
					<Text color={textColorPrimary} fontWeight='bold' fontSize='2xl'>
						{userFirstName} {userLastName}
					</Text>
					<Text
						overflowWrap='anywhere'
						mt='1px'
						color={textColorSecondary}
						fontSize='md'
					>
						{userEmail}
					</Text>
				</Flex>
			</Flex>
		</Card>
	);
}
