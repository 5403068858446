import {
	Badge,
	Box,
	Button,
	Flex,
	Heading,
	SimpleGrid,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from '@chakra-ui/react';
import { getAllConversion } from 'api/conversion';
import Card from 'components/card/Card.js';
import ResultMessage from 'components/modal/ResultMessage';
import CopyableChip from 'components/table/copyableChip';
import DataTable from 'components/table/dataTable';
import { ALERT } from 'constant/messages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDataType } from 'utils/format';
import Information from 'views/client/wallet/components/Information';
import Profile from 'views/client/wallet/components/Profile';
import { columnsDataWallet } from 'views/client/wallet/variable/columnsDataWallet';

export default function Wallet() {
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const cardBalanceBg = useColorModeValue('background.100', 'gray.500');
	const TOKEN = useSelector((state) => state.auth.tokenId);
	const USERID = useSelector((state) => state.auth.userId);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [conversionData, setConversionData] = useState([]);
	const [message, setMessage] = useState({
		state: false,
		status: '',
		description: '',
	});

	const isDesktop = useBreakpointValue({ base: false, md: true });

	const handleMessage = (state, title, subtitle) => {
		setMessage({ state: state, title: title, subtitle: subtitle });
	};

	const handleCloseModal = () => {
		setMessage({
			state: false,
			title: '',
			subtitle: '',
		});
	};

	useEffect(() => {
		document.querySelector('.scroll-body')?.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetchConversions = async () => {
			try {
				setLoading(true);

				const response = await getAllConversion(TOKEN, USERID);
				const result = await response.json();

				if (response.status === 200 && result.status === 'SUCCESS') {
					const resultData = result?.data?.data || [];
					setConversionData(resultData);
				} else {
					handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
				}
			} catch (error) {
				console.error(error);
				handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
			} finally {
				setLoading(false);
			}
		};

		fetchConversions();
	}, [TOKEN, USERID]);

	return (
		<Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
			<SimpleGrid
				mb='20px'
				columns={{ sm: 1, md: 1, lg: 1 }}
				spacing={{ base: '20px' }}
			>
				<Heading color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
					Tu cartera
				</Heading>
				<Flex direction='column'>
					<Profile />
					<Information />

					{!loading && isDesktop && conversionData.length > 0 && (
						<DataTable rows={conversionData} columns={columnsDataWallet} />
					)}
					{!loading && !isDesktop && conversionData.length > 0 && (
						<Flex
							flexDirection={'column'}
							gap={'1rem'}
							my={'1rem'}
							overflowY='auto'
							maxHeight='60vh'
						>
							{conversionData.map((x) => (
								<Card
									key={x.id}
									bgColor={cardBalanceBg}
									py='1rem'
									px='1rem'
									gap='1rem'
								>
									<Flex flexDirection={'column'}>
										<Flex
											gap={'10px'}
											align={'center'}
											mb={'5px'}
											justifyContent={'space-between'}
										>
											{x._brandId.brandName && (
												<Text fontWeight={'bold'}>{x._brandId.brandName}</Text>
											)}
											{x.status && (
												<Badge
													variant='subtle'
													colorScheme={
														x.status.toLowerCase() === 'activo'
															? 'green'
															: 'default'
													}
												>
													{x.status}
												</Badge>
											)}
										</Flex>

										<Text fontSize='sm'>
											{formatDataType('date', x.createdAt)}
										</Text>
									</Flex>

									<Flex width={'-moz-fit-content'}>
										<CopyableChip
											value={x._linkId.shortLink}
											copyable={true}
											style={{ fontSize: '13px' }}
										/>
									</Flex>

									<Text fontWeight={'bold'}>
										CASHBACK: {x.bumbeiAmountCashback || '0'} SATS
									</Text>
								</Card>
							))}
						</Flex>
					)}

					{conversionData.length === 0 && (
						<Card mb='20px'>
							<Flex
								flexDirection={'column'}
								p={'3rem'}
								justifyContent={'center'}
								alignItems={'center'}
							>
								<Text textAlign={'center'}>
									Aquí podrás encontrar todas las conversiones de venta que se
									realicen con tus enlaces Bumbei. <br />
									¡Explora las marcas que tenemos para ti!
								</Text>
								<Button
									variant='brand'
									mt={'25px'}
									onClick={() => navigate('/client/dashboard')}
								>
									Explorar
								</Button>
							</Flex>
						</Card>
					)}
				</Flex>
			</SimpleGrid>
			<ResultMessage
				isOpen={message.state}
				onClose={handleCloseModal}
				message={message}
			/>
		</Box>
	);
}
