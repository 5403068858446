export const ALERT = {
	SUCCESS_TITLE: 'Operación exitosa.',
	SUCCESS_SUBTITLE: 'La información ha sido registrada correctamente.',
	ERROR_TITLE: 'Error en la operación',
	ERROR_SUBTITLE:
		'Favor de intentar más tarde o contactar un agente de soporte.',
	ERROR_FIELDS: 'Favor de ingresar todos los campos requeridos.',
};

export const ERROR = {
	MISSING_FIELDS: 'Favor de ingresar todos los campos requeridos.',
	SOMETHING_WRONG: 'Algo salió mal. Favor de intentar de nuevo.',
	SERVER_INTERNAL: 'Error interno del servidor. Favor de intentar más tarde.',
	USERNAME_WRONG: 'Usuario o contraseña incorrectos.',
	EXISTING_ACCOUNT:
		'Parece que ya existe una cuenta con esta información. Por favor, revisa e intenta de nuevo.',
	VALID_EMAIL: 'Favor de ingresar un correo electrónico válido.',
	VALID_PASSWORD: 'Favor de ingresar una contraseña válida.',
	VALID_EMAIL_PASSWORD:
		'Favor de ingresar una contraseña y un correo electrónico válidos.',
	VALID_TERMS: 'Favor de aceptar los términos y condiciones.',
	VALID_CODE: 'Favor de ingresar un código válido.',
	VALID_LNADDRESS: 'Favor de ingresar una dirección Lightning válida.',
	SAME_PASSWORD:
		'La nueva contraseña no puede ser igual a la contraseña actual. Por favor, elige una contraseña diferente.',
	CONFIRM_PASSWORD:
		'Por favor, verifica que tu nueva contraseña coinicida con el campo de confirmación.',
	CURRENT_PASSWORD:
		'La contraseña actual es incorrecta. Por favor, inténtalo de nuevo.',
	FAILED_TRANSACTION:
		'La transacción no ha podido proceder. Favor de intentar más tarde o consultar a nuestro equipo de soporte.',
	ALREADY_TRANSACTION: 'Lo sentimos, la factura Lightning ha caducado.',
	AMOUNT_TRANSACTION:
		'Lo sentimos, el monto de la factura excede tu balance disponible para retirar. Favor de intentar con una cantidad menor.',
	NOT_FOUND_TRANSACTION:
		'Lo sentimos, no se pudo encontrar una ruta de pago Lightning. Favor de intentar con una nueva factura o consultar a nuestro equipo de soporte.',
	INVALID_INVOICE_TRANSACTION: 'Lo sentimos, la factura Lightning es inválida.',
	EXCHANGE_TRANSACTION:
		'Lo sentimos, de momento el tipo de cambio no está disponible para la divisa indicada. Favor de intentar más tarde. ',
};

export const SUCCESS = {
	EMAIL_SENT: '¡Operación exitosa! Revisa tu correo.',
	EMAIL_SENT_AGAIN: 'Un nuevo código ha sido enviado. Revisa tu correo.',
	RESET_PASSWORD: 'Contraseña restablecida exitosamente.',
	VALIDATE_CODE: 'Código validado exitosamente.',
	PROFILE_UPDATED: 'Tu perfil ha sido actualizado exitosamente.',
	PASSWORD_UPDATED: 'Tu contraseña ha sido actualizada exitosamente.',
	TRANSACTION_SUCCESS:
		'¡Enhorabuena! La transacción solicitada ha sido exitosa.',
};
