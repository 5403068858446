import {
	Alert,
	AlertIcon,
	Button,
	Flex,
	ModalBody,
	ModalCloseButton,
	ModalHeader,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

export default function InvoiceOption({
	invoice,
	setInvoice,
	userBalanceAvailable,
	handleSubmit,
	loading,
}) {
	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.100', 'gray.900');
	const brandColor = useColorModeValue('brand.500', 'gray.100');
	const inputBorder = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');

	return (
		<>
			<ModalHeader
				display='flex'
				alignItems='center'
				gap='10px'
				pt='30px'
				textAlign='left'
			>
				Retiro con factura
			</ModalHeader>
			<ModalCloseButton />
			<ModalBody pb={6}>
				<Text pb={10}>
					Cantidad disponible a retirar: {userBalanceAvailable} SATS
				</Text>

				<Flex direction='column' gap='20px'>
					<InputField
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						_placeholder={{ color: textColorPrimary }}
						borderColor={inputBorder}
						bg={cardBg}
						mb='0px'
						me='30px'
						id='invoice'
						label='Factura'
						placeholder={'Ingresa aquí tu factura'}
						value={invoice}
						onChange={(e) => {
							setInvoice(e.target.value.trim());
						}}
					/>

					{userBalanceAvailable && (
						<Alert status='info' bg={textColorPrimary} borderRadius={'20px'}>
							<AlertIcon color={textColorSecondary} />
							<Text color={textColorSecondary} mr={'5px'}>
								La factura deberá ser por un monto menor o igual al balance
								disponible, de lo contrario, la transacción fallará.
							</Text>
						</Alert>
					)}

					{!userBalanceAvailable && (
						<Alert status='info' bg={textColorPrimary} borderRadius={'20px'}>
							<AlertIcon color={textColorSecondary} />
							<Text color={textColorSecondary} mr={'5px'}>
								No cuentas con balance disponible para retiro.
							</Text>
						</Alert>
					)}

					{userBalanceAvailable && invoice && (
						<Flex justifyContent='center'>
							<Button
								isDisabled={!userBalanceAvailable}
								isLoading={loading}
								borderRadius='16px'
								minW='183px'
								h='44px'
								mt='33px'
								variant={'brand'}
								bg={textColorPrimary}
								fontSize='sm'
								fontWeight='500'
								transition='all 0.3s'
								_hover={{ transform: 'scale(1.05)' }}
								onClick={handleSubmit}
							>
								Retirar
							</Button>
						</Flex>
					)}
				</Flex>
			</ModalBody>
		</>
	);
}
