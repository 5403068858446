// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Unbounded:wght@200..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: 'Inter', sans-serif;
}

option {
	color: black;
}

.chakra-ui-dark option {
	color: #ffff;
}

.css-8y8lw5::-webkit-scrollbar-track {
	background-color: #ffff;
}

.css-8y8lw5::-webkit-scrollbar {
	width: 11px;
	background-color: #ffff;
}

.css-8y8lw5::-webkit-scrollbar-thumb {
	background-color: var(--chakra-colors-black);
	border-radius: 10px;
}

.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar-thumb {
	background-color: var(--chakra-colors-gray-100);
}

.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar-track {
	background-color: var(--chakra-colors-black);
}

.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar {
	background-color: var(--chakra-colors-black);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAIA;CACC,gCAAgC;AACjC;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,WAAW;CACX,uBAAuB;AACxB;;AAEA;CACC,4CAA4C;CAC5C,mBAAmB;AACpB;;AAEA;CACC,+CAA+C;AAChD;;AAEA;CACC,4CAA4C;AAC7C;;AAEA;CACC,4CAA4C;AAC7C","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Unbounded:wght@200..900&display=swap');\n\nbody {\n\tfont-family: 'Inter', sans-serif;\n}\n\noption {\n\tcolor: black;\n}\n\n.chakra-ui-dark option {\n\tcolor: #ffff;\n}\n\n.css-8y8lw5::-webkit-scrollbar-track {\n\tbackground-color: #ffff;\n}\n\n.css-8y8lw5::-webkit-scrollbar {\n\twidth: 11px;\n\tbackground-color: #ffff;\n}\n\n.css-8y8lw5::-webkit-scrollbar-thumb {\n\tbackground-color: var(--chakra-colors-black);\n\tborder-radius: 10px;\n}\n\n.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar-thumb {\n\tbackground-color: var(--chakra-colors-gray-100);\n}\n\n.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar-track {\n\tbackground-color: var(--chakra-colors-black);\n}\n\n.chakra-ui-dark .css-8y8lw5::-webkit-scrollbar {\n\tbackground-color: var(--chakra-colors-black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
