// chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { formatDataType } from 'utils/format';

export function ItemContent({ notification, handleSetAsViewed }) {
	const textColor = useColorModeValue('gray.900', 'gray.100');
	return (
		<Flex flexDirection='column' maxW='400px' onClick={handleSetAsViewed}>
			<Flex justifyContent='space-between'>
				<Text
					display='flex'
					alignItems='center'
					gap='5px'
					mb='5px'
					fontWeight='bold'
					fontSize={{ base: 'md', md: 'md' }}
				>
					{!notification.viewed && (
						<Box
							bg='blue.500'
							color='gray.100'
							borderRadius='50%'
							w={2}
							h={2}
						/>
					)}{' '}
					{notification.title}
				</Text>
				<Text fontSize='sm'>
					{formatDataType('date', notification.createdAt)}
				</Text>
			</Flex>

			<Flex alignItems='center'>
				<Text fontSize={{ base: 'sm', md: 'sm' }} lineHeight={1.2}>
					{notification.description}
				</Text>
			</Flex>
		</Flex>
	);
}
