/* eslint-disable */
import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	HStack,
	Icon,
	List,
	ListItem,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

// Assets
import { FaCircle } from 'react-icons/fa';

// Others
import { useNavigate } from 'react-router-dom';
import { persistor } from 'store';

export function SidebarLinks(props) {
	let location = useLocation();
	let inactiveColor = useColorModeValue('gray.900', 'gray.100');
	let activeIcon = useColorModeValue('brand.500', 'brand.500');

	const { routes, hovered, mini } = props;
	const navigate = useNavigate();

	const handleLogout = () => {
		persistor.purge();
		navigate('/');
	};

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return location.pathname.includes(routeName);
	};

	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (routes) => {
		return routes.map((route, key) => {
			if (
				route.collapse &&
				route.path !== '/auth' &&
				!route.hide &&
				!route.action
			) {
				return (
					<Accordion
						defaultIndex={activeRoute(route.path.toLowerCase()) ? 0 : 'unset'}
						allowToggle
						key={key}
					>
						<AccordionItem maxW='100%' border='none' key={key}>
							<AccordionButton
								display='flex'
								alignItems='center'
								justifyContent='space-between'
								_hover={{
									bg: 'unset',
								}}
								_focus={{
									boxShadow: 'none',
								}}
								borderRadius='8px'
								w={{
									sm: '100%',
									xl: '100%',
								}}
								py='0px'
								bg={'transparent'}
								ms={0}
							>
								{route.icon ? (
									<Flex
										align='center'
										justifyContent={
											mini === false
												? 'space-between'
												: mini === true && hovered === true
												? 'space-between'
												: 'center'
										}
										w='100%'
									>
										<HStack
											mb='6px'
											spacing={
												activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
											}
										>
											<Flex
												w='100%'
												alignItems='center'
												justifyContent='center'
											>
												<Box
													color={
														activeRoute(route.path.toLowerCase())
															? activeIcon
															: inactiveColor
													}
													me={
														mini === false
															? '12px'
															: mini === true && hovered === true
															? '12px'
															: '0px'
													}
													mt='6px'
												>
													{route.icon}
												</Box>
												<Text
													display={
														mini === false
															? 'block'
															: mini === true && hovered === true
															? 'block'
															: 'none'
													}
													me='auto'
													color={
														activeRoute(route.path.toLowerCase())
															? activeIcon
															: inactiveColor
													}
													fontWeight='500'
													fontSize='md'
												>
													{route.name}
												</Text>
											</Flex>
										</HStack>
										<AccordionIcon
											display={
												mini === false
													? 'block'
													: mini === true && hovered === true
													? 'block'
													: 'none'
											}
											ms='auto'
											ml='1rem'
											color={'gray.900'}
											transform={route.icon ? null : 'translateX(-70%)'}
										/>
									</Flex>
								) : (
									<Flex
										pt='0px'
										pb='10px'
										justify={'center'}
										alignItems='center'
										w='100%'
									>
										<HStack
											spacing={
												activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
											}
											ps={
												mini === false
													? '34px'
													: mini === true && hovered === true
													? '34px'
													: '0px'
											}
										>
											<Text
												me='auto'
												color={
													activeRoute(route.path.toLowerCase()) ||
													hovered === true
														? activeIcon
														: inactiveColor
												}
												_hover={{ color: 'blue' }}
												fontWeight='500'
												fontSize='sm'
											>
												{mini === false
													? route.name
													: mini === true && hovered === true
													? route.name
													: route.name[0]}
											</Text>
										</HStack>
										<AccordionIcon
											display={
												mini === false
													? 'block'
													: mini === true && hovered === true
													? 'block'
													: 'none'
											}
											ms='auto'
											ml='1rem'
											color={'gray.900'}
											transform={null}
										/>
									</Flex>
								)}
							</AccordionButton>
							<AccordionPanel
								display={
									mini === false
										? 'block'
										: mini === true && hovered === true
										? 'block'
										: 'flex'
								}
								justifyContent='center'
								alignItems='center'
								flexDirection={'column'}
								pe={route.icon ? '14px !important' : '0px'}
								py='0px'
								ps={route.icon ? '14px !important' : '8px'}
							>
								<List>
									{
										route.icon
											? createLinks(route.items) // for bullet accordion links
											: createAccordionLinks(route.items) // for non-bullet accordion links
									}
								</List>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				);
			} else if (route.path !== '/auth' && !route.hide && !route.action) {
				return (
					<NavLink to={route.layout + route.path} key={key}>
						{route.icon ? (
							<Flex
								align='center'
								justifyContent='space-between'
								w='100%'
								ps={
									mini === false
										? '37px'
										: mini === true && hovered === true
										? '37px'
										: '60px'
								}
								mb='0px'
							>
								<HStack
									mb='6px'
									spacing={
										activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
									}
								>
									<Box w='100%' role='group'>
										<Flex w='100%' alignItems='center' justifyContent='center'>
											<Box
												color={
													activeRoute(route.path.toLowerCase())
														? activeIcon
														: inactiveColor
												}
												me='12px'
												mt='6px'
												_groupHover={{
													color: activeIcon,
													cursor: 'pointer !important',
												}}
											>
												{route.icon}
											</Box>
											<Text
												me='auto'
												color={
													activeRoute(route.path.toLowerCase())
														? activeIcon
														: inactiveColor
												}
												fontWeight='500'
												_groupHover={{
													color: activeIcon,
													cursor: 'pointer !important',
												}}
											>
												{mini === false
													? route.name
													: mini === true && hovered === true
													? route.name
													: ''}
											</Text>
										</Flex>
									</Box>
								</HStack>
							</Flex>
						) : (
							<ListItem ms={null}>
								<Flex
									ps={
										mini === false
											? '34px'
											: mini === true && hovered === true
											? '34px'
											: '0px'
									}
									alignItems='center'
									mb='8px'
								>
									<Text
										color={
											activeRoute(route.path.toLowerCase())
												? activeIcon
												: inactiveColor
										}
										fontWeight='500'
										fontSize='sm'
										_hover={{
											color: activeIcon,
											cursor: 'pointer !important',
										}}
									>
										{mini === false
											? route.name
											: mini === true && hovered === true
											? route.name
											: route.name[0]}
									</Text>
								</Flex>
							</ListItem>
						)}
					</NavLink>
				);
			} else if (route.action === 'logout') {
				return (
					<Flex
						align='center'
						justifyContent='space-between'
						w='100%'
						ps={
							mini === false
								? '37px'
								: mini === true && hovered === true
								? '37px'
								: '60px'
						}
						mb='0px'
						key={key}
					>
						<HStack mb='6px' spacing={'26px'}>
							<Box w='100%' role='group' onClick={handleLogout}>
								<Flex w='100%' alignItems='center' justifyContent='center'>
									<Box
										color={inactiveColor}
										me={
											mini === false
												? '12px'
												: mini === true && hovered === true
												? '12px'
												: '0px'
										}
										mt='6px'
										_groupHover={{
											color: activeIcon,
											cursor: 'pointer !important',
										}}
									>
										{route.icon}
									</Box>
									<Text
										display={
											mini === false
												? 'block'
												: mini === true && hovered === true
												? 'block'
												: 'none'
										}
										me='auto'
										color={inactiveColor}
										fontWeight='500'
										fontSize='md'
										_groupHover={{
											color: activeIcon,
											cursor: 'pointer !important',
										}}
									>
										Cerrar sesión
									</Text>
								</Flex>
							</Box>
						</HStack>
					</Flex>
				);
			}
		});
	};
	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createAccordionLinks = (routes) => {
		return routes.map((route, key) => {
			return (
				<NavLink to={route.layout + route.path} key={key}>
					<ListItem
						ms={
							mini === false
								? '28px'
								: mini === true && hovered === true
								? '28px'
								: '0px'
						}
						display='flex'
						alignItems='center'
						mb='10px'
						key={key}
					>
						<Icon w='6px' h='6px' me='8px' as={FaCircle} color={activeIcon} />
						<Text
							color={
								activeRoute(route.path.toLowerCase())
									? activeIcon
									: inactiveColor
							}
							fontWeight={
								activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
							}
							fontSize='sm'
						>
							{mini === false
								? route.name
								: mini === true && hovered === true
								? route.name
								: route.name[0]}
						</Text>
					</ListItem>
				</NavLink>
			);
		});
	};
	//  BRAND
	return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
