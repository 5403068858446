import {
	Alert,
	AlertIcon,
	Button,
	Flex,
	ModalBody,
	ModalCloseButton,
	ModalHeader,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useState } from 'react';

export default function AutomaticOption({
	amount,
	setAmount,
	userBalanceAvailable,
	userLightningAddress,
	handleSubmit,
	loading,
}) {
	const textColorPrimary = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.100', 'gray.900');
	const brandColor = useColorModeValue('brand.500', 'gray.100');
	const inputBorder = useColorModeValue('gray.900', 'gray.100');
	const cardBg = useColorModeValue('gray.100', 'gray.500');
	const [decimalWarning, setDecimalWarning] = useState(false);

	const handleDecimalWarning = () => {
		setDecimalWarning(true);
		setTimeout(() => {
			setDecimalWarning(false);
		}, 2000);
	};

	return (
		<>
			<ModalHeader
				display='flex'
				alignItems='center'
				gap='10px'
				pt='30px'
				textAlign='left'
			>
				Retiro automático
			</ModalHeader>
			<ModalCloseButton />
			<ModalBody pb={6}>
				<Text pb={10}>
					Cantidad disponible a retirar: {userBalanceAvailable} SATS
				</Text>

				<Flex direction='column' gap='20px'>
					<InputField
						_focus={{ borderWidth: 2, borderColor: brandColor }}
						_placeholder={{ color: textColorPrimary }}
						borderColor={inputBorder}
						bg={cardBg}
						mb='0px'
						id='amount'
						label='Cantidad (SATS)'
						placeholder={'Ingresa aquí tu cantidad en SATS'}
						value={amount}
						inputMode='numeric'
						onChange={(e) => {
							const value = e.target.value.trim();
							// Only allow integer numbers
							if (/^\d*$/.test(value)) {
								setAmount(value);
							} else if (value.includes('.')) {
								handleDecimalWarning();
							}
						}}
					/>

					{decimalWarning && (
						<Alert status='error' bg={'brand.500'} borderRadius={'20px'}>
							<AlertIcon color={textColorPrimary} />
							<Text color={textColorPrimary}>
								Favor de ingresar únicamente números enteros.
							</Text>{' '}
						</Alert>
					)}
					{!decimalWarning &&
						userLightningAddress &&
						+amount < userBalanceAvailable && (
							<Alert status='info' bg={textColorPrimary} borderRadius={'20px'}>
								<AlertIcon color={textColorSecondary} />
								<Text color={textColorSecondary} mr={'5px'}>
									La cantidad ingresada será enviada a la siguiente dirección:
								</Text>{' '}
								<Text color={textColorSecondary}>{userLightningAddress}</Text>
							</Alert>
						)}
					{!decimalWarning && !userLightningAddress && (
						<Alert status='error' bg={'brand.500'} borderRadius={'20px'}>
							<AlertIcon color={textColorPrimary} />
							<Text color={textColorPrimary}>
								Para poder realizar el retiro de forma automática, es necesario
								proporcionar una dirección Lightning.
							</Text>
						</Alert>
					)}
					{!decimalWarning && !userBalanceAvailable && (
						<Alert status='error' bg={'brand.500'} borderRadius={'20px'}>
							<AlertIcon color={textColorPrimary} />
							<Text color={textColorPrimary}>
								No cuentas con balance disponible para retiro.
							</Text>
						</Alert>
					)}
					{!decimalWarning &&
						userLightningAddress &&
						amount &&
						+amount > userBalanceAvailable && (
							<Alert status='error' bg={'brand.500'} borderRadius={'20px'}>
								<AlertIcon color={textColorPrimary} />
								<Text color={textColorPrimary}>
									La cantidad ingresada debe de ser menor o igual al balance
									disponible.
								</Text>
							</Alert>
						)}
					{userLightningAddress &&
						amount &&
						+amount <= userBalanceAvailable && (
							<Flex justifyContent='center'>
								<Button
									isDisabled={!userBalanceAvailable}
									isLoading={loading}
									borderRadius='16px'
									minW='183px'
									h='44px'
									mt='33px'
									variant={'brand'}
									bg={textColorPrimary}
									fontSize='sm'
									fontWeight='500'
									transition='all 0.3s'
									_hover={{ transform: 'scale(1.05)' }}
									onClick={handleSubmit}
								>
									Retirar
								</Button>
							</Flex>
						)}
				</Flex>
			</ModalBody>
		</>
	);
}
