import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
// Chakra imports
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	SimpleGrid,
	Text,
	Tooltip,
	useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import CenteredAuth from 'layouts/auth/types/Centered';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

import { QuestionIcon } from '@chakra-ui/icons';
import { signUp } from 'api/user';
import { ERROR } from 'constant/messages';
import { authActions } from 'store/auth';
import { userActions } from 'store/user';
import { onMessageListener, requestForToken } from '../../../firebase';

function SignUp() {
	// Chakra color mode
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const textColorBrand = useColorModeValue('brand.500', 'brand.500');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const inputBorder = useColorModeValue('gray.900', 'gray.100');

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const refCode = searchParams.get('r') || '';

	// Local states
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isValidValue, setIsValidValue] = useState({
		password: false,
		email: false,
	});

	const [error, setError] = useState({ state: false, message: '' });
	const [loading, setLoading] = useState(false);

	const [registeredUser, setRegisteredUser] = useState({
		firstName: '',
		lastName: '',
		password: '',
		email: '',
		referralCode: refCode,
		termsAccepted: false,
	});

	const validatePassword = (password) => {
		const hasUppercase = /[A-Z]/.test(password);
		const hasLowercase = /[a-z]/.test(password);
		const hasNumber = /[0-9]/.test(password);
		return password.length >= 8 && hasUppercase && hasLowercase && hasNumber;
	};

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setRegisteredUser({
			...registeredUser,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const handleFocus = () => {
		setError({
			state: false,
			message: '',
		});
		setIsSubmitted(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let validPassword = validatePassword(registeredUser.password);
		let validEmail = validateEmail(registeredUser.email);

		// Validation
		setIsValidValue({
			password: validPassword,
			email: validEmail,
		});

		setIsSubmitted(true);

		if (
			validPassword &&
			validEmail &&
			registeredUser.termsAccepted &&
			registeredUser.firstName &&
			registeredUser.lastName
		) {
			try {
				setError({ state: false, message: '' });
				setLoading(true);

				const response = await signUp(registeredUser);
				switch (response.status) {
					case 200: {
						const result = await response.json();
						dispatch(authActions.setTokenId(result.data.token));
						dispatch(authActions.setUserId(result.data.id));
						dispatch(
							userActions.setUserData({
								userEmail: result.data.email,
								userFirstName: result.data.firstName,
								userLastName: result.data.lastName,
								userRole: result.data.role,
								userReferralCode: result.data.referralCode,
								userReferrals: result.data?.Referrals?.length || 0,
							})
						);
						if (result) {
							requestForToken(result.data.token, result.data.id);
							onMessageListener()
								.then((payload) => {
									console.log('Message received. ', payload);
								})
								.catch((err) => console.log('failed: ', err));
							navigate('/client');
						}

						break;
					}
					case 422: {
						setError({
							state: true,
							message: ERROR.EXISTING_ACCOUNT,
						});
						break;
					}
					default: {
						setError({ state: true, message: ERROR.SOMETHING_WRONG });
					}
				}
			} catch (error) {
				setError({
					state: true,
					message: ERROR.SOMETHING_WRONG,
				});
				console.error('Error:', error);
			} finally {
				setLoading(false);
			}
		} else {
			setError({
				state: true,
				message: ERROR.MISSING_FIELDS,
			});
		}
	};

	return (
		<CenteredAuth
			image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
			cardTop={{ base: '140px', md: '14vh' }}
			cardBottom={{ base: '50px', lg: '100px' }}
		>
			<Flex
				maxW='max-content'
				mx={{ base: 'auto', lg: '0px' }}
				me='auto'
				justifyContent='center'
				px={{ base: '20px', md: '0px' }}
				flexDirection='column'
			>
				<Box me='auto'>
					<Heading
						color={textColor}
						fontSize={{ base: '28px', md: '34px', lg: '36px' }}
						mb='10px'
					>
						Registrarse
					</Heading>
					<Text
						mb='36px'
						ms='4px'
						color={textColorSecondary}
						fontWeight='400'
						fontSize='md'
					>
						¡Ingresa tu correo electrónico y contraseña para registrarte!
					</Text>
				</Box>
				{loading && (
					<Flex mb='5' justifyContent={'center'}>
						<CircularProgress isIndeterminate color={textColorBrand} />
					</Flex>
				)}
				{!loading && error.state && (
					<Alert mb='5' status='error'>
						<AlertIcon />
						<AlertDescription>{error.message}</AlertDescription>
					</Alert>
				)}
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', md: '420px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
				>
					<FormControl as='form' onSubmit={handleSubmit}>
						<SimpleGrid
							columns={{ base: '1', md: '2' }}
							gap={{ sm: '10px', md: '26px' }}
						>
							<Flex direction='column'>
								<FormLabel
									display='flex'
									ms='4px'
									fontSize='sm'
									fontWeight='500'
									color={textColor}
									mb='8px'
								>
									Nombre<Text color={brandStars}>*</Text>
								</FormLabel>
								<Input
									id='firstName'
									onFocus={handleFocus}
									fontSize='sm'
									name='firstName'
									value={registeredUser.firstName}
									onChange={handleChange}
									ms={{ base: '0px', md: '4px' }}
									placeholder='Nombre'
									variant='auth'
									mb='24px'
									size='lg'
									borderColor={
										isSubmitted && !registeredUser.firstName
											? 'red.500'
											: inputBorder
									}
								/>
							</Flex>
							<Flex direction='column'>
								<FormLabel
									display='flex'
									ms='4px'
									fontSize='sm'
									fontWeight='500'
									color={textColor}
									mb='8px'
								>
									Apellido<Text color={brandStars}>*</Text>
								</FormLabel>
								<Input
									id='lastName'
									onFocus={handleFocus}
									fontSize='sm'
									name='lastName'
									value={registeredUser.lastName}
									onChange={handleChange}
									ms={{ base: '0px', md: '4px' }}
									placeholder='Apellido'
									variant='auth'
									mb='24px'
									size='lg'
									borderColor={
										isSubmitted && !registeredUser.lastName
											? 'red.500'
											: inputBorder
									}
								/>
							</Flex>
						</SimpleGrid>
						<Flex direction='column'>
							<FormLabel
								display='flex'
								ms='4px'
								fontSize='sm'
								fontWeight='500'
								color={textColor}
								mb='8px'
							>
								Código de referido (opcional)
							</FormLabel>
							<Input
								id='referralCode'
								onFocus={handleFocus}
								variant='auth'
								fontSize='sm'
								name='referralCode'
								value={registeredUser.referralCode}
								onChange={handleChange}
								placeholder='Código de referido'
								mb='24px'
								size='lg'
								borderColor={
									isSubmitted && !registeredUser.referralCode
										? 'red.500'
										: inputBorder
								}
							/>
						</Flex>

						<FormLabel
							display='flex'
							ms='4px'
							fontSize='sm'
							fontWeight='500'
							color={textColor}
							mb='8px'
						>
							Correo electrónico<Text color={brandStars}>*</Text>
						</FormLabel>
						<Input
							id='email'
							onFocus={handleFocus}
							variant='auth'
							fontSize='sm'
							type='email'
							name='email'
							value={registeredUser.email}
							onChange={handleChange}
							placeholder='satoshi@nakamoto.com '
							mb='24px'
							size='lg'
							pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
							title='Favor de ingresar una dirección válida en el formato: mail@example.com'
							borderColor={
								isSubmitted && !isValidValue.email ? 'red.500' : inputBorder
							}
						/>
						<Tooltip
							label={
								'La contraseña debe tener al menos 8 caracteres e incluir al menos una letra mayúscula, una minúscula y un número.'
							}
							aria-label='A tooltip'
						>
							<FormLabel
								ms='4px'
								fontSize='sm'
								fontWeight='500'
								color={textColor}
								display='flex'
								alignItems='center'
							>
								Contraseña<Text color={brandStars}>*</Text>
								<QuestionIcon ml='0.5rem' color={brandStars} />
							</FormLabel>
						</Tooltip>
						<InputGroup size='md'>
							<Input
								id='password'
								onFocus={handleFocus}
								variant='auth'
								fontSize='sm'
								name='password'
								value={registeredUser.password}
								onChange={handleChange}
								ms={{ base: '0px', md: '4px' }}
								placeholder='******'
								mb='24px'
								size='lg'
								type={show ? 'text' : 'password'}
								borderColor={
									isSubmitted && !isValidValue.password
										? 'red.500'
										: inputBorder
								}
							/>
							<InputRightElement display='flex' alignItems='center' mt='4px'>
								<Icon
									color={textColorSecondary}
									_hover={{ cursor: 'pointer' }}
									as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
									onClick={handleClick}
								/>
							</InputRightElement>
						</InputGroup>
						<Flex justifyContent='space-between' align='center' mb='24px'>
							<FormControl display='flex' alignItems='start'>
								<Checkbox
									id='terms-accepted'
									name='termsAccepted'
									isChecked={registeredUser.termsAccepted}
									onChange={handleChange}
									colorScheme='brand'
									me='10px'
									mt='3px'
									borderColor={
										isSubmitted && !registeredUser.termsAccepted
											? 'red.500'
											: inputBorder
									}
								/>
								<FormLabel
									htmlFor='remember-login'
									mb='0'
									fontWeight='normal'
									color={textColor}
									fontSize='sm'
								>
									He leído y acepto los{' '}
									<Link
										href='https://bumbei.com/terms-of-service'
										fontWeight='500'
									>
										Términos y Condiciones
									</Link>
									.
								</FormLabel>
							</FormControl>
						</Flex>
						<Button
							variant='brand'
							fontSize='14px'
							fontWeight='500'
							w='100%'
							h='50'
							mb='24px'
							type='submit'
							isLoading={loading}
							isDisabled={
								!registeredUser.password ||
								!registeredUser.email ||
								!registeredUser.lastName ||
								!registeredUser.firstName
							}
							onClick={handleSubmit}
						>
							Registrarse
						</Button>
					</FormControl>
					<Flex
						flexDirection='column'
						justifyContent='center'
						alignItems='start'
						maxW='100%'
						mt='0px'
					>
						<Text color={textColorSecondary} fontWeight='400' fontSize='sm'>
							¿Ya tienes cuenta?
							<NavLink to='/auth/sign-in'>
								<Text
									color={textColorBrand}
									as='span'
									ms='5px'
									fontWeight='500'
								>
									Inicia sesión
								</Text>
							</NavLink>
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default SignUp;
